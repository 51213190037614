const assets = {
    site: {
        images: {
            logo: require('./images/Reusable Image Assets/PK.png'),
            logo2: require('./images/Reusable Image Assets/PK2.png'),

            backupImage: require('./images/Reusable Image Assets/missingCardImage.jpg'),

            pokeball: require('./images/Reusable Image Assets/pokeball.png'),
            pokeballCaught: require('./images/Reusable Image Assets/pokeball_Caught.png'),

            tcgplayerIcon1:
                'https://mypokellection.com/ImageAssets/TCGplayer-Primary-RGB_100px.png',

            Colorless: require('./images/Reusable Image Assets/Colorless.png'),
            Darkness: require('./images/Reusable Image Assets/Darkness.png'),
            Dragon: require('./images/Reusable Image Assets/Dragon.png'),
            Fairy: require('./images/Reusable Image Assets/Fairy.png'),
            Fighting: require('./images/Reusable Image Assets/Fighting.png'),
            Fire: require('./images/Reusable Image Assets/Fire.png'),
            Grass: require('./images/Reusable Image Assets/Grass.png'),
            Lightning: require('./images/Reusable Image Assets/Lightning.png'),
            Metal: require('./images/Reusable Image Assets/Metal.png'),
            Psychic: require('./images/Reusable Image Assets/Psychic.png'),
            Water: require('./images/Reusable Image Assets/Water.png'),
        },
    },

    english: {
        images: {
            baseSetLogo:
                'https://mypokellection.com/CardAssets/English/BaseSeries/BaseSet/Logo/PokemonLogo.png',
            jungleIcon:
                'https://mypokellection.com/CardAssets/English/BaseSeries/Jungle/Icon/JungleIcon.png',
            jungleLogo:
                'https://mypokellection.com/CardAssets/English/BaseSeries/Jungle/Logo/JungleLogo.webp',
            fossilIcon:
                'https://mypokellection.com/CardAssets/English/BaseSeries/Fossil/Icon/FossilIcon.png',
            fossilLogo:
                'https://mypokellection.com/CardAssets/English/BaseSeries/Fossil/Logo/FossilLogo.webp',
            baseSet2Icon:
                'https://mypokellection.com/CardAssets/English/BaseSeries/BaseSet2/Icon/BaseSet2Icon.png',
            baseSet2Logo:
                'https://mypokellection.com/CardAssets/English/BaseSeries/BaseSet2/Logo/BaseSet2Logo.webp',
            teamRocketIcon:
                'https://mypokellection.com/CardAssets/English/BaseSeries/TeamRocket/Icon/TeamRocketIcon.png',
            teamRocketLogo:
                'https://mypokellection.com/CardAssets/English/BaseSeries/TeamRocket/Logo/TeamRocketLogo.webp',

            gymHeroesIcon:
                'https://mypokellection.com/CardAssets/English/GymSeries/GymHereoes/Icon/GymHeroesIcon.png',
            gymHeroesLogo:
                'https://mypokellection.com/CardAssets/English/GymSeries/GymHereoes/Logo/GymHeroesLogo.webp',
            gymChallengeIcon:
                'https://mypokellection.com/CardAssets/English/GymSeries/GymChallenge/Icon/GymChallengeIcon.png',
            gymChallengeLogo:
                'https://mypokellection.com/CardAssets/English/GymSeries/GymChallenge/Logo/GymChallengeLogo.webp',

            neoGenesisIcon:
                'https://mypokellection.com/CardAssets/English/NeoSeries/NeoGenesis/Icon/NeoGenesisIcon.png',
            neoGenesisLogo:
                'https://mypokellection.com/CardAssets/English/NeoSeries/NeoGenesis/Logo/NeoGenesisLogo.webp',
            neoDiscoveryIcon:
                'https://mypokellection.com/CardAssets/English/NeoSeries/NeoDiscovery/Icon/NeoDiscoveryIcon.png',
            neoDiscoveryLogo:
                'https://mypokellection.com/CardAssets/English/NeoSeries/NeoDiscovery/Logo/NeoDiscoveryLogo.webp',
            neoRevelationIcon:
                'https://mypokellection.com/CardAssets/English/NeoSeries/NeoRevelation/Icon/NeoRevelationIcon.png',
            neoRevelationLogo:
                'https://mypokellection.com/CardAssets/English/NeoSeries/NeoRevelation/Logo/NeoRevelationLogo.webp',
            neoDestinyIcon:
                'https://mypokellection.com/CardAssets/English/NeoSeries/NeoDestiny/Icon/NeoDestinyIcon.png',
            neoDestinyLogo:
                'https://mypokellection.com/CardAssets/English/NeoSeries/NeoDestiny/Logo/NeoDestinyLogo.webp',

            legendaryCollectionIcon:
                'https://mypokellection.com/CardAssets/English/LegendaryCollectionSeries/Icon/LegendaryCollectionIcon.png',
            legendaryCollectionLogo:
                'https://mypokellection.com/CardAssets/English/LegendaryCollectionSeries/Logo/LegendaryCollectionLogo.webp',

            expeditionIcon2:
                'https://mypokellection.com/CardAssets/English/eCardSeries/Expedition/Icon/ExpeditionIcon2.png',
            expeditionLogo:
                'https://mypokellection.com/CardAssets/English/eCardSeries/Expedition/Logo/ExpeditionLogo.webp',
            aquapolisIcon:
                'https://mypokellection.com/CardAssets/English/eCardSeries/Aquapolis/Icon/AquapolisIcon.png',
            aquapolisLogo:
                'https://mypokellection.com/CardAssets/English/eCardSeries/Aquapolis/Logo/AquapolisLogo.webp',
            skyridgeIcon2:
                'https://mypokellection.com/CardAssets/English/eCardSeries/Skyridge/Icon/SkyridgeIcon2.png',
            skyridgeLogo:
                'https://mypokellection.com/CardAssets/English/eCardSeries/Skyridge/Logo/SkyridgeLogo.webp',

            EXRubySapphireIcon:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXRuby%26Sapphire/Icon/EXRubySapphireIcon.png',
            EXRubySapphireLogo:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXRuby%26Sapphire/Logo/EXRubySapphireLogo.png',
            EXSandstormIcon:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXSandstorm/Icon/EXSandstormIcon.png',
            EXSandstormLogo:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXSandstorm/Logo/EXSandstormLogo.png',
            EXDragonIcon:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXDragon/Icon/EXDragonIcon.png',
            EXDragonLogo:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXDragon/Logo/EXDragonLogo.png',
            EXTeamMagmavsTeamAquaIcon:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXTeamMagmavsTeamAqua/Icon/EXTeamMagmavsTeamAquaIcon.png',
            EXTeamMagmavsTeamAquaLogo:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXTeamMagmavsTeamAqua/Logo/EXTeamMagmavsTeamAquaLogo.png',
            EXHiddenLegendsIcon:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXHiddenLegends/Icon/EXHiddenLegendsIcon.png',
            EXHiddenLegendsLogo:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXHiddenLegends/Logo/EXHiddenLegendsLogo.png',
            EXRireRedLeafGreenIcon:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXFireRedLeafGreen/Icon/EXRireRedLeafGreenIcon.png',
            EXRireRedLeafGreenLogo:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXFireRedLeafGreen/Logo/EXRireRedLeafGreenLogo.png',
            EXTeamRocketReturnsIcon:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXTeamRocketReturns/Icon/EXTeamRocketReturnsIcon.png',
            EXTeamRocketReturnsLogo:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXTeamRocketReturns/Logo/EXTeamRocketReturnsLogo.png',

            EXDeoxysIcon:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXDeoxys/Icon/EXDeoxysIcon.png',
            EXDeoxysLogo:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXDeoxys/Logo/EXDeoxysLogo.png',

            EXEmeraldIcon:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXEmerald/Icon/EXEmeraldIcon.png',
            EXEmeraldLogo:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXEmerald/Logo/EXEmeraldLogo2.png',
            EXUnseenForcesIcon:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXUnseenForces/Icon/EXUnseenForcesIcon.png',
            EXUnseenForcesLogo:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXUnseenForces/Logo/EXUnseenForcesLogo.png',
            EXDeltaSpeciesIcon:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXDeltaSpecies/Icon/EXDeltaSpeciesIcon.png',
            EXDeltaSpeciesLogo:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXDeltaSpecies/Logo/EXDeltaSpeciesLogo.png',
            EXLegendMakerIcon:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXLegendMaker/Icon/EXLegendMakerIcon.png',
            EXLegendMakerLogo:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXLegendMaker/Logo/EXLegendMakerLogo.png',
            EXHolonPhantomsIcon:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXHolonPhantoms/Icon/EXHolonPhantomsIcon.png',
            EXHolonPhantomsLogo:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXHolonPhantoms/Logo/EXHolonPhantomsLogo.png',

            EXCrystalGuardiansIcon:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXCrystalGuardians/Icon2/EXCrystalGuardiansIcon2.png',
            EXCrystalGuardiansLogo:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXCrystalGuardians/Logo/EXCrystalGuardiansLogo.png',
            EXDragonFrontiersIcon:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXDragonFrontiers/Icon/EXDragonFrontiersIcon.png',
            EXDragonFrontiersLogo:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXDragonFrontiers/Logo/EXDragonFrontiersLogo.png',
            EXPowerKeepersIcon:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXPowerKeepers/Icon/EXPowerKeepersIcon.png',
            EXPowerKeepersLogo:
                'https://mypokellection.com/CardAssets/English/EXSeries/EXPowerKeepers/Logo/EXPowerKeepersLogo.png',

            DiamondPearlIcon:
                'https://mypokellection.com/CardAssets/English/DiamondPearlSeries/DiamondAndPearl/Icon/DiamondPearlIcon.png',
            DiamondPearlLogo:
                'https://mypokellection.com/CardAssets/English/DiamondPearlSeries/DiamondAndPearl/Logo/DiamondPearlLogo.png',
            GreatEncountersIcon:
                'https://mypokellection.com/CardAssets/English/DiamondPearlSeries/GreatEncounters/Icon/GreatEncountersIcon.png',
            GreatEncountersLogo:
                'https://mypokellection.com/CardAssets/English/DiamondPearlSeries/GreatEncounters/Logo/GreatEncountersLogo.png',
            LegendsAwakenedIcon:
                'https://mypokellection.com/CardAssets/English/DiamondPearlSeries/LegendsAwakened/Icon/LegendsAwakenedIcon.png',
            LegendsAwakenedLogo:
                'https://mypokellection.com/CardAssets/English/DiamondPearlSeries/LegendsAwakened/Logo/LegendsAwakenedLogo.png',
            MajesticDawnIcon:
                'https://mypokellection.com/CardAssets/English/DiamondPearlSeries/MajesticDawn/Icon/MajesticDawnIcon.png',
            MajesticDawnLogo:
                'https://mypokellection.com/CardAssets/English/DiamondPearlSeries/MajesticDawn/Logo/MajesticDawnLogo.png',
            MysteriousTreasuresIcon:
                'https://mypokellection.com/CardAssets/English/DiamondPearlSeries/MysteriousTreasures/Icon/MysteriousTreasuresIcon.png',
            MysteriousTreasuresLogo:
                'https://mypokellection.com/CardAssets/English/DiamondPearlSeries/MysteriousTreasures/Logo/MysteriousTreasuresLogo.png',
            SecretWondersIcon:
                'https://mypokellection.com/CardAssets/English/DiamondPearlSeries/SecretWonders/Icon/SecretWondersIcon.png',
            SecretWondersLogo:
                'https://mypokellection.com/CardAssets/English/DiamondPearlSeries/SecretWonders/Logo/SecretWondersLogo.png',
            StormfrontIcon:
                'https://mypokellection.com/CardAssets/English/DiamondPearlSeries/Stormfront/Icon/StormfrontIcon.png',
            StormfrontLogo:
                'https://mypokellection.com/CardAssets/English/DiamondPearlSeries/Stormfront/Logo/StormfrontLogo.png',

            PlatinumIcon:
                'https://mypokellection.com/CardAssets/English/PlatinumSeries/Platinum/Icon/PlatinumIcon.png',
            PlatinumLogo:
                'https://mypokellection.com/CardAssets/English/PlatinumSeries/Platinum/Logo/PlatinumLogo.png',
            RisingRivalsIcon:
                'https://mypokellection.com/CardAssets/English/PlatinumSeries/RisingRivals/Icon/RisingRivalsIcon.png',
            RisingRivalsLogo:
                'https://mypokellection.com/CardAssets/English/PlatinumSeries/RisingRivals/Logo/RisingRivalsLogo.png',
            SupremeVictorsIcon:
                'https://mypokellection.com/CardAssets/English/PlatinumSeries/SupremeVictors/Icon/SupremeVictorsIcon.png',
            SupremeVictorsLogo:
                'https://mypokellection.com/CardAssets/English/PlatinumSeries/SupremeVictors/Logo/SupremeVictorsLogo.png',
            ArceusIcon:
                'https://mypokellection.com/CardAssets/English/PlatinumSeries/Arceus/Icon/ArceusIcon.png',
            ArceusLogo:
                'https://mypokellection.com/CardAssets/English/PlatinumSeries/Arceus/Logo/ArceusLogo.png',

            CallofLegendsIcon:
                'https://mypokellection.com/CardAssets/English/HGSSSeries/CoL/Icon/CallofLegendsIcon.png',
            CallofLegendsLogo:
                'https://mypokellection.com/CardAssets/English/HGSSSeries/CoL/Logo/CallofLegendsLogo.png',
            heartGoldSoulSilverIcon:
                'https://mypokellection.com/CardAssets/English/HGSSSeries/HGSS/Icon/heartGoldSoulSilverIcon.png',
            HeartGoldSoulSilverLogo:
                'https://mypokellection.com/CardAssets/English/HGSSSeries/HGSS/Logo/HeartGoldSoulSilverLogo.png',
            TriumphantIcon:
                'https://mypokellection.com/CardAssets/English/HGSSSeries/Triumphant/Icon/TriumphantIcon.png',
            TriumphantLogo:
                'https://mypokellection.com/CardAssets/English/HGSSSeries/Triumphant/Logo/TriumphantLogo.png',
            UndauntedIcon:
                'https://mypokellection.com/CardAssets/English/HGSSSeries/Undaunted/Icon/UndauntedIcon.png',
            UndauntedLogo:
                'https://mypokellection.com/CardAssets/English/HGSSSeries/Undaunted/Logo/UndauntedLogo.png',
            UnleashedIcon:
                'https://mypokellection.com/CardAssets/English/HGSSSeries/Unleashed/Icon/UnleashedIcon.png',
            UnleashedLogo:
                'https://mypokellection.com/CardAssets/English/HGSSSeries/Unleashed/Logo/UnleashedLogo.png',

            BlackWhiteIcon:
                'https://mypokellection.com/CardAssets/English/BWSeries/BlackWhite/Icon/BlackWhiteIcon.png',
            BlackWhiteLogo:
                'https://mypokellection.com/CardAssets/English/BWSeries/BlackWhite/Logo/BlackWhiteLogo.png',
            BoundariesCrossedIcon:
                'https://mypokellection.com/CardAssets/English/BWSeries/BoundariesCrossed/Icon/BoundariesCrossedIcon.png',
            BoundariesCrossedLogo:
                'https://mypokellection.com/CardAssets/English/BWSeries/BoundariesCrossed/Logo/BoundariesCrossedLogo.png',
            DarkExplorersIcon:
                'https://mypokellection.com/CardAssets/English/BWSeries/DarkExplorers/Icon/DarkExplorersIcon.png',
            DarkExplorersLogo:
                'https://mypokellection.com/CardAssets/English/BWSeries/DarkExplorers/Logo/DarkExplorersLogo.png',
            DragonsExaltedIcon:
                'https://mypokellection.com/CardAssets/English/BWSeries/DragonsExalted/Icon/DragonsExaltedIcon.png',
            DragonsExaltedLogo:
                'https://mypokellection.com/CardAssets/English/BWSeries/DragonsExalted/Logo/DragonsExaltedLogo.png',
            DragonVaultIcon:
                'https://mypokellection.com/CardAssets/English/BWSeries/DragonVault/Icon/DragonVaultIcon.png',
            DragonVaultLogo:
                'https://mypokellection.com/CardAssets/English/BWSeries/DragonVault/Logo/DragonVaultLogo.png',
            EmergingPowersIcon:
                'https://mypokellection.com/CardAssets/English/BWSeries/EmergingPowers/Icon/EmergingPowersIcon.png',
            EmergingPowersLogo:
                'https://mypokellection.com/CardAssets/English/BWSeries/EmergingPowers/Logo/EmergingPowersLogo.png',
            LegendaryTreasuresIcon:
                'https://mypokellection.com/CardAssets/English/BWSeries/LegendaryTreasures/Icon/LegendaryTreasuresIcon.png',
            LegendaryTreasuresLogo:
                'https://mypokellection.com/CardAssets/English/BWSeries/LegendaryTreasures/Logo/LegendaryTreasuresLogo.png',
            NextDestiniesIcon:
                'https://mypokellection.com/CardAssets/English/BWSeries/NextDestinies/Icon/NextDestiniesIcon.png',
            NextDestiniesLogo:
                'https://mypokellection.com/CardAssets/English/BWSeries/NextDestinies/Logo/NextDestiniesLogo.png',
            NobleVictoriesIcon:
                'https://mypokellection.com/CardAssets/English/BWSeries/NobelVictories/Icon/NobleVictoriesIcon.png',
            NobleVictoriesLogo:
                'https://mypokellection.com/CardAssets/English/BWSeries/NobelVictories/Logo/NobleVictoriesLogo.png',
            PlasmaBlastIcon:
                'https://mypokellection.com/CardAssets/English/BWSeries/PlasmaBlast/Icon/PlasmaBlastIcon.png',
            PlasmaBlastLogo:
                'https://mypokellection.com/CardAssets/English/BWSeries/PlasmaBlast/Logo/PlasmaBlastLogo.png',
            PlasmaFreezeIcon:
                'https://mypokellection.com/CardAssets/English/BWSeries/PlasmaFreeze/Icon/PlasmaFreezeIcon.png',
            PlasmaFreezeLogo:
                'https://mypokellection.com/CardAssets/English/BWSeries/PlasmaFreeze/Logo/PlasmaFreezeLogo.png',
            PlasmaStormIcon:
                'https://mypokellection.com/CardAssets/English/BWSeries/PlasmaStorm/Icon/PlasmaStormIcon.png',
            PlasmaStormLogo:
                'https://mypokellection.com/CardAssets/English/BWSeries/PlasmaStorm/Logo/PlasmaStormLogo.png',

            AncientOriginsIcon:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY7/Icon/AncientOriginsIcon.png',
            AncientOriginsLogo:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY7/Logo/AncientOriginsLogo.png',
            BreakPointIcon:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY9/Icon/BreakPointIcon.png',
            BreakPointLogo:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY9/Logo/BreakPointLogo.png',
            BreakThroughIcon:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY8/Icon/BreakThroughIcon.png',
            BreakThroughLogo:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY8/Logo/BreakThroughLogo.png',
            DoubleCrisisIcon:
                'https://mypokellection.com/CardAssets/English/XYSeries/DoubleCrisis/Icon/DoubleCrisisIcon.png',
            DoubleCrisisLogo:
                'https://mypokellection.com/CardAssets/English/XYSeries/DoubleCrisis/Logo/DoubleCrisisLogo.png',
            EvolutionsIcon:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY12/Icon/EvolutionsIcon.png',
            EvolutionsLogo:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY12/Logo/EvolutionsLogo.png',
            FatesCollideIcon:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY10/Icon/FatesCollideIcon.png',
            FatesCollideLogo:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY10/Logo/FatesCollideLogo.png',
            FlashFireIcon:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY2/Icon/FlashFireIcon.png',
            FlashFireLogo:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY2/Logo/FlashFireLogo.png',
            FuriousFistsIcon:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY3/Icon/FuriousFistsIcon.png',
            FuriousFistsLogo:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY3/Logo/FuriousFistsLogo.png',
            GenerationsIcon:
                'https://mypokellection.com/CardAssets/English/XYSeries/Generations/Icon/GenerationsIcon.png',
            GenerationsLogo:
                'https://mypokellection.com/CardAssets/English/XYSeries/Generations/Logo/GenerationsLogo.png',
            PhantonForcesIcon:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY4/Icon/PhantonForcesIcon.png',
            PhantonForcesLogo:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY4/Logo/PhantonForcesLogo.png',
            PrimalClashIcon:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY5/Icon/PrimalClashIcon.png',
            PrimalClashLogo:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY5/Logo/PrimalClashLogo.png',
            RoaringSkiesIcon:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY6/Icon/RoaringSkiesIcon.png',
            RoaringSkiesLogo:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY6/Logo/RoaringSkiesLogo.png',
            SteamSiegeIcon:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY11/Icon/SteamSiegeIcon.png',
            SteamSiegeLogo:
                'https://mypokellection.com/CardAssets/English/XYSeries/XY11/Logo/SteamSiegeLogo.png',
            XYIcon: 'https://mypokellection.com/CardAssets/English/XYSeries/XY1/Icon/XYIcon.png',
            XYLogo: 'https://mypokellection.com/CardAssets/English/XYSeries/XY1/Logo/XYLogo.png',
            XYStarterSetIcon:
                'https://mypokellection.com/CardAssets/English/XYSeries/XYStarterSet/Icon/XYStarterSetIcon.png',
            XYStarterSetLogo:
                'https://mypokellection.com/CardAssets/English/XYSeries/XYStarterSet/Logo/XYStarterSetLogo.png',

            BurningShadowsIcon:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM3/Icon/BurningShadowsIcon.png',
            BurningShadowsLogo:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM3/Logo/BurningShadowsLogo.png',
            CelestialStormIcon:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM7/Icon/CelestialStormIcon.png',
            CelestialStormLogo:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM7/Logo/CelestialStormLogo.png',
            CosmicEclipseIcon:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM12/Icon/CosmicEclipseIcon.png',
            CosmicEclipseLogo:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM12/Logo/CosmicEclipseLogo.png',
            CrimsonInvasionIcon:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM4/Icon/CrimsonInvasionIcon.png',
            CrimsonInvasionLogo:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM4/Logo/CrimsonInvasionLogo.png',
            DetectivePikachuIcon:
                'https://mypokellection.com/CardAssets/English/SMSeries/DET/Icon/DetectivePikachuIcon.png',
            DetectivePikachuLogo:
                'https://mypokellection.com/CardAssets/English/SMSeries/DET/Logo/DetectivePikachuLogo.png',
            DragonMajestyIcon:
                'https://mypokellection.com/CardAssets/English/SMSeries/DragonMajesty/Icon/DragonMajestyIcon.png',
            DragonMajestyLogo:
                'https://mypokellection.com/CardAssets/English/SMSeries/DragonMajesty/Logo/DragonMajestyLogo.png',
            ForbiddenLightIcon:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM6/Icon/ForbiddenLightIcon.png',
            ForbiddenLightLogo:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM6/Logo/ForbiddenLightLogo.png',
            GuardiansRisingIcon:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM2/Icon/GuardiansRisingIcon.png',
            GuardiansRisingLogo:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM2/Logo/GuardiansRisingLogo.png',
            HiddenFatesIcon:
                'https://mypokellection.com/CardAssets/English/SMSeries/HF/Icon/HiddenFatesIcon.png',
            HiddenFatesLogo:
                'https://mypokellection.com/CardAssets/English/SMSeries/HF/Logo/HiddenFatesLogo.png',
            LostThunderIcon:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM8/Icon/LostThunderIcon.png',
            LostThunderLogo:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM8/Logo/LostThunderLogo.png',
            ShiningLegendsIcon:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM35/Icon/ShiningLegendsIcon.png',
            ShiningLegendsLogo:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM35/Logo/ShiningLegendsLogo.png',
            SunMoonIcon:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM1/Icon/SunMoonIcon.png',
            SunMoonLogo:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM1/Logo/SunMoonLogo.png',
            TeamUpIcon:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM9/Icon/TeamUpIcon.png',
            TeamUpLogo:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM9/Logo/TeamUpLogo.png',
            UltraPrismLogo:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM5/Logo/UltraPrismLogo.png',
            UltraPrismIcon:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM5/Icon/UltraPrismIcon.png',
            UnbrokenBondsIcon:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM10/Icon/UnbrokenBondsIcon.png',
            UnbrokenBondsLogo:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM10/Logo/UnbrokenBondsLogo.png',
            UnifiedMindsIcon:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM11/Icon/UnifiedMindsIcon.png',
            UnifiedMindsLogo:
                'https://mypokellection.com/CardAssets/English/SMSeries/SM11/Logo/UnifiedMindsLogo.png',

            AstralRadianceIcon:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH10/Icon/AstralRadianceIcon.png',
            AstralRadianceLogo:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH10/Logo/AstralRadianceLogo.png',
            BattleStylesLogo:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH5/Logo/BattleStyelsLogo.png',
            BattleStylesIcon:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH5/Icon/BattleStylesIcon.png',
            BrilliantStarsLogo:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH09/Logo/BrialliantStartsLogo.png',
            BrilliantStarsIcon:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH09/Icon/BrilliantStarsIcon.png',
            CelebrationsIcon:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/CEL/Icon/CelebrationsIcon.png',
            CelebrationsLogo:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/CEL/Logo/CelebrationsLogo.png',
            ChampionsPathIcon:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH3pt5/Icon/ChampionsPathIcon.png',
            ChampionsPathLogo:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH3pt5/Logo/ChampionsPathLogo.png',
            ChillingReignIcon:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH06/Icon/ChillingReignIcon.png',
            ChillingReignLogo:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH06/Logo/ChillingReignLogo.png',
            CrownZenithIcon:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH12pt5/Icon/CrownZenithIcon.png',
            CrownZenithLogo:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH12pt5/Logo/CrownZenithLogo.png',
            DarknessAblazeIcon:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH3/Icon/DarknessAblazeIcon.png',
            DarknessAblazeLogo:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH3/Logo/DarknessAblazeLogo.png',
            EvolvingSkiesIcon:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH07/Icon/EvolvingSkiesIcon.png',
            EvolvingSkiesLogo:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH07/Logo/EvolvingSkiesLogo.png',
            FusionStrikeIcon:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH08/Icon/FusionStrikeIcon.png',
            FusionStrikeLogo:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH08/Logo/FusionStrikeLogo.png',
            LostOriginIcon:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH11/Icon/LostOriginIcon.png',
            LostOriginLogo:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH11/Logo/LostOriginLogo.png',
            PokemonGoIcon:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/POGO/Icon/PokemonGoIcon.png',
            PokemonGoLogo:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/POGO/Logo/PokemonGoLogo.png',
            RebelClashIcon:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH2/Icon/RebelClashIcon.png',
            RebelClashLogo:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH2/Logo/RebelClashLogo.png',
            ShiningFatesIcon:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH4pt5/Icon/ShiningFatesIcon.png',
            ShiningFatesLogo:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH4pt5/Logo/ShiningFatesLogo.png',
            SilverTempestIcon:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH12/Icon/SilverTempestIcon.png',
            SilverTempestLogo:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH12/Logo/SilverTempestLogo.png',
            SwordShieldIcon:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH1/Icon/SwordShieldIcon.png',
            SwordShieldLogo:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH1/Logo/SwordShieldLogo.png',
            VividVoltageIcon:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH4/Icon/VividVoltageIcon.png',
            VividVoltageLogo:
                'https://mypokellection.com/CardAssets/English/SWSHSeries/SWSH4/Logo/VividVoltageLogo.png',

            ScarletVioletIcon:
                'https://mypokellection.com/CardAssets/English/SVSeries/ScarletViolet/Icon/ScarletVioletIcon.png',
            ScarletVioletLogo:
                'https://mypokellection.com/CardAssets/English/SVSeries/ScarletViolet/Logo/ScarletVioletLogo.png',
            PaldeaEvolvedIcon:
                'https://mypokellection.com/CardAssets/English/SVSeries/PaldeaEvolved/Icon/PaldeaEvolvedIcon.png',
            PaldeaEvolvedLogo:
                'https://mypokellection.com/CardAssets/English/SVSeries/PaldeaEvolved/Logo/PaldeaEvolvedLogo.png',
            ObsidianFlamesIcon:
                'https://mypokellection.com/CardAssets/English/SVSeries/ObsidianFlames/Icon/ObsidianFlamesIcon.png',
            ObsidianFlamesLogo:
                'https://mypokellection.com/CardAssets/English/SVSeries/ObsidianFlames/Logo/ObsidianFlamesLogo.png',
            ScarletViolet151Icon:
                'https://mypokellection.com/CardAssets/English/SVSeries/ScarletViolet151/Icon/ScarletViolet151Icon.png',
            ScarletViolet151Logo:
                'https://mypokellection.com/CardAssets/English/SVSeries/ScarletViolet151/Logo/ScarletViolet151Logo.png',
            ParadoxRiftIcon:
                'https://mypokellection.com/CardAssets/English/SVSeries/ParadoxRift/Icon/ParadoxRiftIcon.png',
            ParadoxRiftLogo:
                'https://mypokellection.com/CardAssets/English/SVSeries/ParadoxRift/Logo/ParadoxRiftLogo.png',
            PaldeanFatesIcon:
                'https://mypokellection.com/CardAssets/English/SVSeries/PaldeanFates/Icon/PaldeanFatesIcon.png',
            PaldeanFatesLogo:
                'https://mypokellection.com/CardAssets/English/SVSeries/PaldeanFates/Logo/PaldeanFatesLogo.png',
            TemporalForcesIcon:
                'https://mypokellection.com/CardAssets/English/SVSeries/TemporalForces/Icon/TemporalForcesIcon.png',
            TemporalForcesLogo:
                'https://mypokellection.com/CardAssets/English/SVSeries/TemporalForces/Logo/TemporalForcesLogo.png',
            TwilightMasqueradeIcon:
                'https://mypokellection.com/CardAssets/English/SVSeries/TwilightMasquerade/Icon/TwilightMasqueradeIcon.png',
            TwilightMasqueradeLogo:
                'https://mypokellection.com/CardAssets/English/SVSeries/TwilightMasquerade/Logo/TwilightMasqueradeLogo.png',
            ShroudedFableIcon:
                'https://mypokellection.com/CardAssets/English/SVSeries/ShroudedFable/Icon/ShroudedFableIcon.png',
            ShroudedFableLogo:
                'https://mypokellection.com/CardAssets/English/SVSeries/ShroudedFable/Logo/ShroudedFableLogo.png',
            StellarCrownIcon:
                'https://mypokellection.com/CardAssets/English/SVSeries/StellarCrown/Icon/StellarCrownIcon.png',
            StellarCrownLogo:
                'https://mypokellection.com/CardAssets/English/SVSeries/StellarCrown/Logo/StellarCrownLogo.png',
        },
    },

    pokedex: {
        pokemon: {
            PK123: 'https://mypokellection.com/Pokedex/123.png',
        },
    },
};

export default assets;
