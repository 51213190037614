import React from 'react';
import { Box, Typography } from '@mui/material';

const AdminPageIndex: React.FC = () => {
    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Admin Panel
            </Typography>
            <Typography variant="body1">
                Welcome to the admin panel. Please select a section from the sidebar to get started.
            </Typography>
        </Box>
    );
};

export default AdminPageIndex;
