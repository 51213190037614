import React from 'react';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';

export interface Filters {
    status: 'processed' | 'unprocessed';
}

interface FilterProps {
    filters: Filters;
    setFilters: (filters: Filters) => void;
}

const CameoCardFilter: React.FC<FilterProps> = ({ filters, setFilters }) => {
    return (
        <Box
            sx={{
                mb: 3,
                display: 'flex',
                width: '100%',
            }}
        >
            <ToggleButtonGroup
                value={filters.status}
                exclusive
                onChange={(_, newStatus) => {
                    if (newStatus !== null) {
                        setFilters({ ...filters, status: newStatus });
                    }
                }}
                aria-label="featured status filter"
                sx={{
                    width: '100%',
                    '& .MuiToggleButton-root': {
                        flex: 1,
                        textTransform: 'none',
                    },
                }}
            >
                <ToggleButton value="unprocessed">Unprocessed</ToggleButton>
                <ToggleButton value="processed">Processed</ToggleButton>
            </ToggleButtonGroup>
        </Box>
    );
};

export default CameoCardFilter;
