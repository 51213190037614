import React from 'react';
import { Paper, Typography, Box, CircularProgress } from '@mui/material';

interface CardImageHeaderProps {
    loading?: boolean;
}

const CardImageHeader: React.FC<CardImageHeaderProps> = ({ loading = false }) => {
    return (
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">Card Image Manager</Typography>
            </Box>
        </Paper>
    );
};

export default CardImageHeader;
