import React from 'react';
import { Paper, Typography, Grid, Box } from '@mui/material';
import assets from '../../assets';

interface PokedexHeaderProps {
    userName: string;
    totalPokemon: number;
    caughtPokemon: number;
    caughtFeaturedCards: number;
    caughtCameoCards: number;
    totalFeaturedCards: number;
    totalCameoCards: number;
    totalMarketValue: number;
    isAuthenticated: boolean;
}

const DesktopPokedexHeader: React.FC<PokedexHeaderProps> = ({
    userName,
    totalPokemon,
    caughtPokemon,
    caughtFeaturedCards,
    caughtCameoCards,
    totalFeaturedCards,
    totalCameoCards,
    totalMarketValue,
}) => {
    return (
        <>
            <div style={{ marginBottom: '5px' }}>
                <Paper elevation={4} sx={{ p: 2, width: '100%' }}>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            {userName ? `${userName}` : 'Guest'}'s Pokédex
                        </Typography>
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box display="flex" alignItems="center">
                                    <img
                                        loading="lazy"
                                        className="desktop-pokeball"
                                        src={assets.site.images.pokeballCaught}
                                        alt="Pokéball"
                                        style={{
                                            marginRight: '8px',
                                            width: '24px',
                                            height: '24px',
                                        }}
                                    />
                                    <Typography variant="body1">
                                        Pokémon: {caughtPokemon}/{totalPokemon}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="body1">
                                        Collected Featured Pokemon Cards: {caughtFeaturedCards}/
                                        {totalFeaturedCards}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="body1">
                                        Collected Cameo Pokemon Cards: {caughtCameoCards}/
                                        {totalCameoCards}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="body1">
                                        My Pokedex Value: ${totalMarketValue.toFixed(2)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </>
    );
};

export default DesktopPokedexHeader;
