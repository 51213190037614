import { Drawer, List, Stack, Toolbar } from '@mui/material';
import { useEffect, useState } from 'react';
import assets from '../../assets';
import colorConfigs from '../../configs/colorConfigs';
import sizeConfigs from '../../configs/sizeConfigs';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Link } from 'react-router-dom';
import { RouteType } from '../../routes/config';
import Avatar from '@mui/material/Avatar';
import '../../css/sideBar.css';
import { useSidebarRoutes } from '../../routes';
import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';

const Sidebar = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const { authenticated, userName, favoritePokemonImage } = auth;
    const TCGState = useSelector((state: RootState) => state.TCGState);
    const authenticatedStorage = localStorage.getItem('authenticated');
    const routes = useSidebarRoutes();
    const [filteredRoutes, setFilteredRoutes] = useState<RouteType[]>([]);

    useEffect(() => {
        //console.log('Routes updated:', routes);
        //console.log('Auth status:', { authenticated, authenticatedStorage });
        //console.log('TCG State:', TCGState);
        if (routes) {
            const adminKey = localStorage.getItem('adminKey');
            
            const filtered = routes.filter((route) => {
                if (route.beta) {
                    return false;
                }

                if (route.path?.startsWith('/admin') && !adminKey) {
                    return false;
                }

                // Recursively filter the child routes of this route
                if (route.child) {
                    route.child = route.child.filter((childRoute) => {
                        if (childRoute.beta) return false;
                        if (childRoute.path?.startsWith('/admin') && !adminKey) return false;
                        return true;
                    });
                }

                if (authenticated || authenticatedStorage === 'true') {
                    return (
                        route.sidebarProps &&
                        (route.tcgState === 'All' ||
                            route.tcgState === TCGState ||
                            route.state === 'Logout' ||
                            route.tcgState === 'Admin')
                    );
                } else {
                    return (
                        route.sidebarProps &&
                        (route.tcgState === 'All' ||
                            route.tcgState === TCGState ||
                            route.state === 'Login')
                    );
                }
            });

            //console.log('Setting filtered routes:', filtered);
            setFilteredRoutes(filtered);
        }
    }, [routes, authenticated, authenticatedStorage, TCGState]);

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: sizeConfigs.sidebar.width,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: sizeConfigs.sidebar.width,
                    boxSizing: 'border-box',
                    borderRight: '0px',
                    backgroundColor: colorConfigs.sidebar.bg,
                    color: colorConfigs.sidebar.color,
                },
            }}
        >
            <List disablePadding>
                <Toolbar>
                    <Stack sx={{ width: '100%' }} direction="row" justifyContent="center">
                        <Link to="/">
                            <img
                                style={{ width: 'auto', height: 'auto', maxWidth: '100px' }}
                                src={assets.site.images.logo}
                                alt=""
                            ></img>
                        </Link>
                    </Stack>
                </Toolbar>

                <div className="sidebar-userProfile">
                    {authenticated ? (
                        <>
                            <Link to={`/u/${userName ?? 'Guest'}`}>
                                <Avatar
                                    alt={userName ?? 'Guest'}
                                    src={favoritePokemonImage || assets.pokedex.pokemon.PK123}
                                    key={favoritePokemonImage}
                                />
                            </Link>
                            <Link to={`/u/${userName ?? 'Guest'}`}>
                                <p>{userName ?? 'Guest'}</p>
                            </Link>
                        </>
                    ) : (
                        <>
                            <Avatar alt="Guest" src={assets.pokedex.pokemon.PK123} />
                            <p>Guest</p>
                        </>
                    )}
                </div>

                {filteredRoutes.map((route, index) =>
                    route.sidebarProps ? (
                        route.child ? (
                            <SidebarItemCollapse item={route} key={`${route.path}-${index}`} />
                        ) : (
                            <SidebarItem item={route} key={`${route.path}-${index}`} />
                        )
                    ) : null
                )}
            </List>
            <div>
                <Link className="privacypolicy" to="/PrivacyPolicy">
                    Privacy Policy
                </Link>
            </div>
            <div>
                <p className="footer-text">
                    MyPokellection is not affiliated with, sponsored or endorsed by, or in any way
                    associated with Pokemon or The Pokemon Company International Inc
                </p>
            </div>
        </Drawer>
    );
};

export default Sidebar;
