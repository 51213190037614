import React from 'react';
import { Paper, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { styled } from '@mui/material/styles';

// Define the SetInfo type
interface SetInfo {
    setPrintRuns?: {
        firstEditionSet?: { buttonDisplay: string };
        shadowlessSet?: { buttonDisplay: string };
        unlimitedEditionSet?: { buttonDisplay: string };
        fourthPrintSet?: { buttonDisplay: string };
    };
    showAllCards?: boolean;
}

export interface ToggleButtonsProps {
    setInfo: SetInfo;
    select: string;
    handleToggle: (event: React.MouseEvent<HTMLElement>, newAlignment: string) => void;
    showFirstEdition: boolean;
    showShadowlessSet: boolean;
    showfourthPrintSet: boolean;
    showReverseSet: boolean;
    showSubSet: boolean;
    type?: string;
}

interface ColoredToggleButtonProps {
    customColor: string;
}

const ColoredToggleButton = styled(ToggleButton)<ColoredToggleButtonProps>(
    ({ theme, customColor }) => ({
        color: customColor,
        '&.Mui-selected': {
            color: theme.palette.getContrastText(customColor),
            backgroundColor: customColor,
            '&:hover': {
                backgroundColor: customColor,
                opacity: 0.9,
            },
        },
    })
);

const ToggleButtons: React.FC<ToggleButtonsProps> = ({
    setInfo,
    select,
    handleToggle,
    showFirstEdition,
    showShadowlessSet,
    showfourthPrintSet,
    showReverseSet,
    showSubSet,
    type,
}) => (
    <Paper elevation={4} style={{ padding: '16px', width: '100%' }}>
        <ToggleButtonGroup
            value={select}
            exclusive
            onChange={handleToggle}
            aria-label="text alignment"
            style={{ width: '100%', display: 'flex', overflowX: 'auto' }}
        >
            {showFirstEdition && (
                <ColoredToggleButton
                    style={{ flex: '1 0 25%' }}
                    value="First Edition"
                    customColor="#0288d1"
                >
                    {setInfo.setPrintRuns?.firstEditionSet?.buttonDisplay}
                </ColoredToggleButton>
            )}
            {showShadowlessSet && (
                <ColoredToggleButton
                    style={{ flex: '1 0 25%' }}
                    value="Shadowless"
                    customColor="#9c27b0"
                >
                    {setInfo.setPrintRuns?.shadowlessSet?.buttonDisplay}
                </ColoredToggleButton>
            )}
            <ColoredToggleButton
                style={{ flex: '1 0 25%' }}
                value="Unlimited"
                customColor="#ed6c02"
            >
                {setInfo.setPrintRuns?.unlimitedEditionSet?.buttonDisplay}
            </ColoredToggleButton>
            {showfourthPrintSet && (
                <ColoredToggleButton
                    style={{ flex: '1 0 25%' }}
                    value="4th Print"
                    customColor="#d32f2f"
                >
                    {setInfo.setPrintRuns?.fourthPrintSet?.buttonDisplay}
                </ColoredToggleButton>
            )}
            {showReverseSet && (
                <ColoredToggleButton
                    style={{ flex: '1 0 25%' }}
                    value="Reverse Set"
                    customColor="#7b1fa2"
                >
                    Reverse Set
                </ColoredToggleButton>
            )}
            {showSubSet && (
                <ColoredToggleButton
                    style={{ flex: '1 0 25%' }}
                    value="Sub-Set"
                    customColor="#0288d1"
                >
                    Sub Set
                </ColoredToggleButton>
            )}
            {(type !== 'WOTC' || setInfo.showAllCards === true) && (
                <ColoredToggleButton
                    style={{ flex: '1 0 25%' }}
                    value="All Cards"
                    customColor="#2e7d32"
                >
                    All Cards
                </ColoredToggleButton>
            )}
        </ToggleButtonGroup>
    </Paper>
);

export default ToggleButtons;
