import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
    Typography,
    Paper,
    Box,
    Button,
    IconButton,
    Avatar,
    Grid,
    Collapse,
    ToggleButtonGroup,
    ToggleButton,
    Fab,
    Zoom,
    useScrollTrigger,
    CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NEWDesktopCardDisplay from './NEWDesktopCardDisplay';
import { Pokemon } from '../../hooks/usePokeDex';
import assets from '../../assets';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useAuth } from '../../context/AuthContext';
import { useCardFunctions } from '../../hooks/useCardFunctions';
import {
    addCardToCollection,
    removeCardFromCollection,
} from '../../components/common/reuseablethings';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ToastContainer } from 'react-toastify';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface DesktopPokemonDetailProps {
    pokemon: Pokemon;
}

interface ColoredToggleButtonProps {
    customColor: string;
}

const ColoredToggleButton = styled(ToggleButton)<ColoredToggleButtonProps>(
    ({ theme, customColor }) => ({
        color: customColor,
        '&.Mui-selected': {
            color: theme.palette.getContrastText(customColor),
            backgroundColor: customColor,
            '&:hover': {
                backgroundColor: customColor,
                opacity: 0.9,
            },
        },
    })
);

function ScrollTop() {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Zoom in={trigger}>
            <Fab
                onClick={handleClick}
                size="small"
                aria-label="scroll back to top"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <KeyboardArrowUpIcon />
            </Fab>
        </Zoom>
    );
}

const DesktopPokemonDetail: React.FC<DesktopPokemonDetailProps> = ({ pokemon }) => {
    const userName = useSelector((state: RootState) => state.auth.userName);
    const reduxIsAuthenticated = useSelector((state: RootState) => state.auth.authenticated);
    const { authParams, isAuthenticated } = useAuth();

    // Use the useCardFunctions hook to fetch data
    const {
        featuredCards,
        cameoCards,
        featuredCaughtPokemonCardIds,
        cameoCaughtPokemonCardIds,
        updateFeaturedCaughtIds,
        updateCameoCaughtIds,
        fetchFeaturedCards,
        fetchCameoCards,
        loading,
    } = useCardFunctions(userName || 'Guest', reduxIsAuthenticated, pokemon?.id);

    const [expandedFeatured, setExpandedFeatured] = useState(true);
    const [expandedCameo, setExpandedCameo] = useState(true);
    const [selectedCards, setSelectedCards] = useState<number[]>([]);
    const [cardType, setCardType] = useState<'main' | 'reverse' | 'all'>('all');

    // Fetch cards when the component mounts or when the pokemon changes
    useEffect(() => {
        if (pokemon) {
            fetchFeaturedCards(pokemon.id);
            fetchCameoCards(pokemon.id);
        }
    }, [pokemon, fetchFeaturedCards, fetchCameoCards]);

    // Function to toggle expansion of featured and cameo card sections
    const toggleExpanded = (section: 'featured' | 'cameo') => {
        if (section === 'featured') {
            setExpandedFeatured(!expandedFeatured);
        } else {
            setExpandedCameo(!expandedCameo);
        }
    };

    // Function to change the selected status of a card
    const changeCardSelectedStatus = (cardId: number) => {
        setSelectedCards((prevSelectedCards) =>
            prevSelectedCards.includes(cardId)
                ? prevSelectedCards.filter((id) => id !== cardId)
                : [...prevSelectedCards, cardId]
        );
    };

    // Function to change the collection status of a card
    const changeCardCollectionStatus = async (cards: any[], card: any) => {
        if (!isAuthenticated || !authParams) {
            return;
        }

        const newIsCardInCollection = !card.isCardInCollection;
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );
        const queryParams = new URLSearchParams(stringAuthParams).toString();

        try {
            if (newIsCardInCollection) {
                await addCardToCollection(card.id, queryParams);
            } else {
                await removeCardFromCollection(card.id, queryParams);
            }

            // Check both lists and update accordingly
            const isFeatureCard = featuredCards.some((c) => c.id === card.id);
            const isCameoCard = cameoCards?.some((c) => c.id === card.id);

            if (isFeatureCard) {
                updateFeaturedCaughtIds(card.id, newIsCardInCollection);
            }
            if (isCameoCard) {
                updateCameoCaughtIds(card.id, newIsCardInCollection);
            }
        } catch (error) {
            // Handle error
        }
    };

    // Handle toggle for card type filter
    const handleToggle = (
        event: React.MouseEvent<HTMLElement>,
        newCardType: 'main' | 'reverse' | 'all'
    ) => {
        if (newCardType !== null) {
            setCardType(newCardType);
        }
    };

    // Use useMemo to create filtered and mapped card arrays
    const filteredAndMappedFeaturedCards = useMemo(() => {
        // Add null check for featuredCards
        if (!featuredCards) {
            return [];
        }

        const filtered = featuredCards.filter((card) =>
            cardType === 'all'
                ? true
                : cardType === 'main'
                  ? !card.isReverseHolo
                  : card.isReverseHolo
        );
        const mapped = filtered.map((card) => ({
            ...card,
            isCardInCollection: featuredCaughtPokemonCardIds.includes(card.id),
        }));
        return mapped;
    }, [featuredCards, cardType, featuredCaughtPokemonCardIds]);

    const filteredAndMappedCameoCards = useMemo(() => {
        // Add null check for cameoCards
        if (!cameoCards) {
            return [];
        }

        const filtered = cameoCards.filter((card) =>
            cardType === 'all'
                ? true
                : cardType === 'main'
                  ? !card.isReverseHolo
                  : card.isReverseHolo
        );
        const mapped = filtered.map((card) => ({
            ...card,
            isCardInCollection: cameoCaughtPokemonCardIds.includes(card.id),
        }));
        return mapped;
    }, [cameoCards, cardType, cameoCaughtPokemonCardIds]);

    const filteredCaughtFeaturedCount = useMemo(() => {
        const count = featuredCaughtPokemonCardIds.filter((id) =>
            filteredAndMappedFeaturedCards.some((card) => card.id === id)
        ).length;

        return count;
    }, [featuredCaughtPokemonCardIds, filteredAndMappedFeaturedCards]);

    const filteredCaughtCameoCount = useMemo(() => {
        const count = cameoCaughtPokemonCardIds.filter((id) =>
            filteredAndMappedCameoCards.some((card) => card.id === id)
        ).length;

        return count;
    }, [cameoCaughtPokemonCardIds, filteredAndMappedCameoCards]);

    // If loading, show loading indicator
    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress size={40} />
            </Box>
        );
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <Box sx={{ p: 2 }}>
                <Button component={Link} to="/MyPokedex" variant="contained" sx={{ mb: 2 }}>
                    Back to Pokédex
                </Button>

                <div style={{ marginBottom: '5px' }}>
                    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={1}
                        >
                            <Box display="flex" alignItems="center">
                                <Typography variant="h6" mr={2}>
                                    {pokemon.name}
                                </Typography>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Generation: {pokemon.generation} | {pokemon.name} #
                                    {pokemon.number}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Avatar
                                src={pokemon.image}
                                alt={pokemon.name}
                                sx={{ width: 80, height: 80, mr: 2 }}
                            />
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={3}>
                                    <Typography variant="body2">Total Cards</Typography>
                                    <Typography variant="body1">
                                        {featuredCaughtPokemonCardIds.length +
                                            cameoCaughtPokemonCardIds.length}
                                        /
                                        {pokemon.number_featured_cards + pokemon.number_cameo_cards}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body2">Featured Cards</Typography>
                                    <Typography variant="body1">
                                        {featuredCaughtPokemonCardIds.length}/
                                        {pokemon.number_featured_cards}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body2">Cameo Cards</Typography>
                                    <Typography variant="body1">
                                        {cameoCaughtPokemonCardIds.length}/
                                        {pokemon.number_cameo_cards}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </div>

                <div style={{ marginBottom: '5px' }}>
                    <Paper
                        elevation={4}
                        style={{ padding: '16px', width: '100%', marginBottom: '16px' }}
                    >
                        <ToggleButtonGroup
                            value={cardType}
                            exclusive
                            onChange={handleToggle}
                            aria-label="card type"
                            style={{ width: '100%', display: 'flex', overflowX: 'auto' }}
                        >
                            <ColoredToggleButton
                                style={{ flex: '1 0 33%' }}
                                value="main"
                                customColor="#ed6c02"
                            >
                                Main Cards
                            </ColoredToggleButton>
                            <ColoredToggleButton
                                style={{ flex: '1 0 33%' }}
                                value="reverse"
                                customColor="#7b1fa2"
                            >
                                Reverse Cards
                            </ColoredToggleButton>
                            <ColoredToggleButton
                                style={{ flex: '1 0 33%' }}
                                value="all"
                                customColor="#2e7d32"
                            >
                                All Cards
                            </ColoredToggleButton>
                        </ToggleButtonGroup>
                    </Paper>
                </div>

                <div style={{ marginBottom: '5px' }}>
                    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={1}
                        >
                            <Box display="flex" alignItems="center">
                                <Typography variant="h6" mr={2}>
                                    Featured Cards
                                </Typography>
                                <Typography variant="subtitle2" color="text.secondary">
                                    {filteredCaughtFeaturedCount}/
                                    {filteredAndMappedFeaturedCards.length}
                                </Typography>
                            </Box>
                            <IconButton onClick={() => toggleExpanded('featured')} size="small">
                                {expandedFeatured ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </Box>
                        <Collapse in={expandedFeatured}>
                            <NEWDesktopCardDisplay
                                key={`featured-${filteredAndMappedFeaturedCards.length}`}
                                cardsToDisplay={filteredAndMappedFeaturedCards}
                                assets={{
                                    site: {
                                        images: {
                                            backupImage: assets.site.images.backupImage,
                                            pokeballCaught: assets.site.images.pokeballCaught,
                                            pokeball: assets.site.images.pokeball,
                                        },
                                    },
                                }}
                                showPriceData={true}
                                changeCardSelectedStatus={changeCardSelectedStatus}
                                changeCardCollectionStatus={changeCardCollectionStatus}
                            />
                        </Collapse>
                    </Paper>
                </div>

                <div style={{ marginBottom: '5px' }}>
                    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={1}
                        >
                            <Box display="flex" alignItems="center">
                                <Typography variant="h6" mr={2}>
                                    Cameo Cards
                                </Typography>
                                <Typography variant="subtitle2" color="text.secondary">
                                    {filteredCaughtCameoCount}/{filteredAndMappedCameoCards.length}
                                </Typography>
                            </Box>
                            <IconButton onClick={() => toggleExpanded('cameo')} size="small">
                                {expandedCameo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </Box>
                        <Collapse in={expandedCameo}>
                            <NEWDesktopCardDisplay
                                key={`cameo-${filteredAndMappedCameoCards.length}`}
                                cardsToDisplay={filteredAndMappedCameoCards}
                                assets={{
                                    site: {
                                        images: {
                                            backupImage: assets.site.images.backupImage,
                                            pokeballCaught: assets.site.images.pokeballCaught,
                                            pokeball: assets.site.images.pokeball,
                                        },
                                    },
                                }}
                                showPriceData={true}
                                changeCardSelectedStatus={changeCardSelectedStatus}
                                changeCardCollectionStatus={changeCardCollectionStatus}
                            />
                        </Collapse>
                    </Paper>
                </div>
            </Box>

            <ScrollTop />
        </>
    );
};

export default DesktopPokemonDetail;
