import { createReducer } from '@reduxjs/toolkit';

const initialuserSetCollectedCardsState = 0;

const userSetCollectedCardsReducer = createReducer(initialuserSetCollectedCardsState, {
    SET_USERSETCOLLECTEDCARDS: (state, action) => {
        return action.payload;
    },
});

export default userSetCollectedCardsReducer;
