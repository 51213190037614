import { toast } from 'react-toastify';

export interface AuthParams {
    [key: string]: any;
}

interface FollowApiResponse {
    success: boolean;
    message?: string;
    isFollowing?: boolean;
    followerCount?: number;
}

const handleApiError = (error: unknown, errorMessage: string) => {
    toast.error(errorMessage);
    return { success: false };
};

const createQueryString = (params: Record<string, string | number | boolean>) => {
    return new URLSearchParams(
        Object.entries(params).map(([key, value]) => [key, String(value)])
    ).toString();
};

export const checkFollowStatus = async (
    authParams: AuthParams,
    targetUserName: string,
    currentUserName: string
): Promise<FollowApiResponse> => {
    try {
        const queryString = createQueryString({
            ...authParams,
            targetUserName,
            currentUserName,
        });

        const response = await fetch(`/api/checkFollow?${queryString}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        const result: FollowApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to check follow status');
        }

        return result;
    } catch (error) {
        return handleApiError(error, 'An error occurred while checking follow status');
    }
};

export const getFollowersCount = async (userName: string): Promise<FollowApiResponse> => {
    try {
        const queryString = createQueryString({ userName });
        const response = await fetch(`/api/getFollowersCount?${queryString}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        const result: FollowApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch follower count');
        }

        return result;
    } catch (error) {
        return handleApiError(error, 'An error occurred while fetching follower count');
    }
};

export const followUser = async (
    authParams: AuthParams,
    targetUserName: string,
    currentUserName: string
): Promise<FollowApiResponse> => {
    try {
        const response = await fetch('/api/followUser', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ...authParams,
                targetUserName,
                currentUserName,
            }),
        });

        const result: FollowApiResponse = await response.json();

        if (result.success) {
            toast.success(result.message || 'Successfully followed user', { autoClose: 1000 });
        } else {
            toast.error('Failed to follow user');
        }

        return result;
    } catch (error) {
        return handleApiError(error, 'An error occurred while following the user');
    }
};

export const unfollowUser = async (
    authParams: AuthParams,
    targetUserName: string,
    currentUserName: string
): Promise<FollowApiResponse> => {
    try {
        const response = await fetch('/api/unfollowUser', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ...authParams,
                targetUserName,
                currentUserName,
            }),
        });

        const result: FollowApiResponse = await response.json();

        if (result.success) {
            toast.success(result.message || 'Successfully unfollowed user', { autoClose: 1000 });
        } else {
            toast.error('Failed to unfollow user');
        }

        return result;
    } catch (error) {
        return handleApiError(error, 'An error occurred while unfollowing the user');
    }
};
