import { useState, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import * as adminApi from '../api/adminApi';
import { CategoryCard, FeaturedCard, CameoCard } from '../types/admin';

export const useAdminFunctions = () => {
    const { authParams } = useAuth();
    const [loading, setLoading] = useState(false);

    const getCardCategoryStats = useCallback(async () => {
        if (!authParams) {
            console.error('authParams is null or undefined');
            return { totalCards: 0, processedCards: 0 };
        }

        setLoading(true);
        try {
            const result = await adminApi.getCardCategoryStats(authParams);
            if (result.success) {
                return {
                    totalCards: result.totalCards || 0,
                    processedCards: result.processedCards || 0,
                };
            }
            return { totalCards: 0, processedCards: 0 };
        } finally {
            setLoading(false);
        }
    }, [authParams]);

    const getCardCategoryPokemonCardData = useCallback(async (): Promise<CategoryCard[]> => {
        if (!authParams) {
            console.error('authParams is null or undefined');
            return [];
        }

        setLoading(true);
        try {
            const result = await adminApi.getCardCategoryPokemonCardData(authParams);
            if (result.success && result.cards) {
                return result.cards.map((card) => ({
                    ...card,
                    isProcessed: Boolean(card.isProcessed),
                }));
            }
            return [];
        } finally {
            setLoading(false);
        }
    }, [authParams]);

    const updateCardCategory = useCallback(
        async (cardId: number, cardType: string): Promise<boolean> => {
            if (!authParams) {
                console.error('authParams is null or undefined');
                return false;
            }

            setLoading(true);
            try {
                const result = await adminApi.updateCardCategory(authParams, cardId, cardType);
                return result.success;
            } finally {
                setLoading(false);
            }
        },
        [authParams]
    );

    const getFeaturedCardStats = useCallback(async () => {
        if (!authParams) {
            console.error('authParams is null or undefined');
            return { totalCards: 0, featuredCards: 0 };
        }

        setLoading(true);
        try {
            const result = await adminApi.getFeaturedCardStats(authParams);
            if (result.success) {
                return {
                    totalCards: result.totalCards || 0,
                    featuredCards: result.featuredCards || 0,
                };
            }
            return { totalCards: 0, featuredCards: 0 };
        } finally {
            setLoading(false);
        }
    }, [authParams]);

    const getFeaturedPokemonCards = useCallback(
        async (setId?: number | null): Promise<FeaturedCard[]> => {
            if (!authParams) {
                console.error('authParams is null or undefined');
            return [];
        }

        setLoading(true);
        try {
            const result = await adminApi.getFeaturedPokemonCards(authParams, setId);
            if (result.success && result.cards) {
                return result.cards.map((card) => ({
                    ...card,
                    isFeaturedComplete: Boolean(card.isFeaturedComplete),
                }));
            }
            return [];
        } finally {
            setLoading(false);
        }
    }, [authParams]);

    const updateCardFeatured = useCallback(
        async (
            cardId: number,
            featuredPokemonId: number,
            hasCameoPokemon: boolean
        ): Promise<boolean> => {
            if (!authParams) {
                console.error('authParams is null or undefined');
                return false;
            }

            setLoading(true);
            try {
                const result = await adminApi.updateCardFeatured(
                    authParams,
                    cardId,
                    featuredPokemonId,
                    hasCameoPokemon
                );
                return result.success;
            } finally {
                setLoading(false);
            }
        },
        [authParams]
    );

    const getCameoCardStats = useCallback(async () => {
        if (!authParams) {
            console.error('authParams is null or undefined');
            return { totalCards: 0, cameoCards: 0 };
        }

        setLoading(true);
        try {
            const result = await adminApi.getCameoCardStats(authParams);
            if (result.success) {
                return {
                    totalCards: result.totalCards || 0,
                    cameoCards: result.cameoCards || 0,
                };
            }
            return { totalCards: 0, cameoCards: 0 };
        } finally {
            setLoading(false);
        }
    }, [authParams]);

    const getCameoPokemonCards = useCallback(async (): Promise<CameoCard[]> => {
        if (!authParams) {
            console.error('authParams is null or undefined');
            return [];
        }

        setLoading(true);
        try {
            const result = await adminApi.getCameoPokemonCards(authParams);
            if (result.success && result.cards) {
                return result.cards.map((card) => ({
                    ...card,
                    isCameoComplete: Boolean(card.isCameoComplete),
                }));
            }
            return [];
        } finally {
            setLoading(false);
        }
    }, [authParams]);

    const updateCardCameo = useCallback(
        async (cardId: number, cameoPokemonId: number): Promise<boolean> => {
            if (!authParams) {
                console.error('authParams is null or undefined');
                return false;
            }

            setLoading(true);
            try {
                const result = await adminApi.updateCardCameo(authParams, cardId, cameoPokemonId);
                return result.success;
            } finally {
                setLoading(false);
            }
        },
        [authParams]
    );

    const getCurrentFeaturedPokemon = useCallback(
        async (cardId: number) => {
            if (!authParams) {
                console.error('authParams is null or undefined');
                return [];
            }

            setLoading(true);
            try {
                const result = await adminApi.getCurrentFeaturedPokemon(authParams, cardId);
                if (result.success && result.pokemon) {
                    return result.pokemon;
                }
                return [];
            } finally {
                setLoading(false);
            }
        },
        [authParams]
    );

    const getCurrentCameoPokemon = useCallback(
        async (cardId: number) => {
            if (!authParams) {
                console.error('authParams is null or undefined');
                return [];
            }

            setLoading(true);
            try {
                const result = await adminApi.getCurrentCameoPokemon(authParams, cardId);
                if (result.success && result.pokemon) {
                    return result.pokemon;
                }
                return [];
            } finally {
                setLoading(false);
            }
        },
        [authParams]
    );

    const completeFeaturedCard = useCallback(
        async (cardId: number, hasCameoPokemon: boolean): Promise<boolean> => {
            if (!authParams) {
                console.error('authParams is null or undefined');
                return false;
            }

            setLoading(true);
            try {
                const result = await adminApi.completeFeaturedCard(
                    authParams,
                    cardId,
                    hasCameoPokemon
                );
                return result.success;
            } finally {
                setLoading(false);
            }
        },
        [authParams]
    );

    const completeCameoCard = useCallback(
        async (cardId: number, isCameoComplete: boolean): Promise<boolean> => {
            if (!authParams) {
                console.error('authParams is null or undefined');
                return false;
            }

            setLoading(true);
            try {
                const result = await adminApi.completeCameoCard(
                    authParams,
                    cardId,
                    isCameoComplete
                );
                return result.success;
            } finally {
                setLoading(false);
            }
        },
        [authParams]
    );

    const removeFeaturedPokemon = useCallback(
        async (cardId: number, pokemonId: number): Promise<boolean> => {
            if (!authParams) {
                console.error('authParams is null or undefined');
                return false;
            }

            setLoading(true);
            try {
                const result = await adminApi.removeFeaturedPokemon(authParams, cardId, pokemonId);
                return result.success;
            } finally {
                setLoading(false);
            }
        },
        [authParams]
    );

    const removeCameoPokemon = useCallback(
        async (cardId: number, pokemonId: number): Promise<boolean> => {
            if (!authParams) {
                console.error('authParams is null or undefined');
                return false;
            }

            setLoading(true);
            try {
                const result = await adminApi.removeCameoPokemon(authParams, cardId, pokemonId);
                return result.success;
            } finally {
                setLoading(false);
            }
        },
        [authParams]
    );

    return {
        getCardCategoryStats,
        getCardCategoryPokemonCardData,
        updateCardCategory,
        getFeaturedCardStats,
        getFeaturedPokemonCards,
        updateCardFeatured,
        getCameoCardStats,
        getCameoPokemonCards,
        updateCardCameo,
        getCurrentFeaturedPokemon,
        completeFeaturedCard,
        removeFeaturedPokemon,
        getCurrentCameoPokemon,
        completeCameoCard,
        removeCameoPokemon,
        loading,
    };
};
