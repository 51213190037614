import {
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import colorConfigs from '../../configs/colorConfigs';
import { RouteType } from '../../routes/config';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import SidebarItem from './SidebarItem';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

type Props = {
    item: RouteType;
};

const SidebarItemCollapse = ({ item }: Props) => {
    const [open, setOpen] = useState(true);

    const { appState } = useSelector((state: RootState) => state.appState);

    useEffect(() => {
        if (appState.includes(item.state)) {
            setOpen(true);
        }
    }, [appState, item]);

    const childKey = item.child?.map(child => child.path).join('-') || '';

    return item.sidebarProps ? (
        <>
            <ListItemButton
                onClick={() => setOpen(!open)}
                sx={{
                    '&: hover': {
                        backgroundColor: colorConfigs.sidebar.hoverBg,
                    },
                    paddingX: '24px',
                }}
            >
                <ListItemIcon
                    sx={{
                        color: colorConfigs.sidebar.color,
                    }}
                >
                    {item.sidebarProps.icon && item.sidebarProps.icon}
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    primary={<Typography>{item.sidebarProps.displayText}</Typography>}
                />
                {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" key={childKey}>
                <List>
                    {item.child?.map((route, index) =>
                        route.sidebarProps ? (
                            route.child ? (
                                <SidebarItemCollapse 
                                    item={route} 
                                    key={`${route.path}-${index}`} 
                                />
                            ) : (
                                <SidebarItem 
                                    item={route} 
                                    key={`${route.path}-${index}`} 
                                />
                            )
                        ) : null
                    )}
                </List>
            </Collapse>
        </>
    ) : null;
};

export default SidebarItemCollapse;
