import { createReducer } from '@reduxjs/toolkit';

const initialPageReducer = 'login';

const pageReducer = createReducer(initialPageReducer, {
    SET_PAGE: (state, action) => {
        return action.payload;
    },
});

export default pageReducer;
