import { createReducer } from '@reduxjs/toolkit';

const initialLoginPageState = 'false';

const loginPageReducer = createReducer(initialLoginPageState, {
    SET_LOGINPAGE: (state, action) => {
        return action.payload;
    },
});

export default loginPageReducer;
