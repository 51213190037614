import { createReducer } from '@reduxjs/toolkit';
import assets from '../../assets';

const initialCardsDataState = [
    {
        id: 1,
        setcardindex: 1,
        number: 1,
        name: 'Test Card 1',
        imagelocation: assets.site.images.backupImage,
        isCardInCollection: false,
    },
    {
        id: 2,
        setcardindex: 2,
        number: 2,
        name: 'Test Card 2',
        imagelocation: assets.site.images.backupImage,
        isCardInCollection: false,
    },
];

const cardsDataReducer = createReducer(initialCardsDataState, {
    SET_CARDSDATA: (state, action) => {
        return action.payload;
    },
});

export default cardsDataReducer;
