import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/login.css';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { usePokeDex, Pokemon } from '../../hooks/usePokeDex';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {
    setAuthenticated,
    setUserName,
    setFavoritePokemonImage,
    setFavoritePokemonId,
} from '../../redux/slices/authSlice';
import CountrySelector from '../../components/selectors/CountrySelector';
import { useAuth } from '../../context/AuthContext';

type Props = {};

const LoginPage: React.FC<Props> = () => {
    ReactGA.initialize('G-677WMVXWLS');
    const navigate = useNavigate();

    let authenticatedStorage = localStorage.getItem('authenticated');
    let [loginFormState, setLoginFormState] = useState('NeedAccount');

    const dispatch = useDispatch();
    const { setAuthParams, setIsAuthenticated } = useAuth();

    useEffect(() => {
        ReactGA.send(window.location.pathname + window.location.search);
    }, [dispatch]);

    useEffect(() => {
        if (authenticatedStorage === 'true') {
            dispatch({ type: 'SET_AUTHENTICATED', payload: true });
        } else {
            dispatch({ type: 'SET_AUTHENTICATED', payload: false });
        }
    }, [dispatch, authenticatedStorage]);

    useEffect(() => {
        dispatch({ type: 'SET_PAGE', payload: 'Login' });
    });

    function handleAPIResult(result: { success: any; failed: any; message: String }) {
        if (result.success) {
            toast.success(`${result.message}`, {
                autoClose: 1000,
            });
        } else if (result.failed) {
            toast.error(`${result.message}`, {
                autoClose: 1000,
            });
        }
    }

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [favoritePokemon, setFavoritePokemon] = useState<Pokemon | null>(null);
    const [autocompleteValue, setAutocompleteValue] = useState<Pokemon | null>(null);
    const { pokedex, loading } = usePokeDex();
    const [countryCode, setCountryCode] = useState<string>('');

    function handleNeedAccountClick() {
        setLoginFormState('NeedAccount');
    }

    function handleHaveAccountClick() {
        setLoginFormState('HaveAccount');
    }

    function handleForgotPassword() {
        setLoginFormState('ForgotPassword');
    }

    function handleLogoutClick() {
        localStorage.removeItem('authenticated');
        localStorage.removeItem('userName');
        localStorage.removeItem('userUUID');
        localStorage.removeItem('userId');
        localStorage.removeItem('deviceUUID');
        localStorage.removeItem('pokedexCache');
        localStorage.removeItem('favoritePokemonId');
        localStorage.removeItem('favoritePokemonImage');
        localStorage.removeItem('authParams');
        localStorage.removeItem('authState');
        localStorage.clear();
        dispatch(setAuthenticated(false));
        dispatch(setUserName('Guest'));
        dispatch(setFavoritePokemonImage(null));
        dispatch(setFavoritePokemonId(null));
        setLoginFormState('HaveAccount');
        toast.success(`Success: Logged out`, {
            autoClose: 1000,
        });
    }

    const login = (event: { preventDefault: () => void }) => {
        event.preventDefault();
        if (!email || !password) {
            toast.error(`Error: email or password is blank`, {
                autoClose: 1000,
            });
        } else {
            const body = JSON.stringify({
                email,
                password,
            });

            fetch('/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body,
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data && data.user_uuid && data.user && data.device_uuid && data.user_name) {
                        const authParams = {
                            userUUID: data.user_uuid,
                            deviceUUID: data.device_uuid,
                            userName: data.user_name,
                        };

                        // Update AuthContext
                        setAuthParams(authParams);
                        setIsAuthenticated(true);

                        // Update Redux store
                        dispatch(setAuthenticated(true));
                        dispatch(setUserName(data.user_name));

                        // Update localStorage
                        localStorage.setItem('authenticated', 'true');
                        localStorage.setItem('userName', data.user_name);
                        localStorage.setItem('userUUID', data.user_uuid);
                        localStorage.setItem('deviceUUID', data.device_uuid);

                        return fetch(`/api/getUserDetails?userName=${data.user_name}`);
                    } else {
                        throw new Error('Invalid response data');
                    }
                })
                .then((response) => response.json())
                .then((userDetailsData) => {
                    if (userDetailsData.success) {
                        const favoritePokemonImage =
                            userDetailsData.userDetails.favoritePokemonImage;
                        const favoritePokemonId = userDetailsData.userDetails.favoritePokemonId;

                        // Update Redux store
                        dispatch(setFavoritePokemonImage(favoritePokemonImage));
                        dispatch(setFavoritePokemonId(favoritePokemonId));

                        // Update localStorage
                        localStorage.setItem('favoritePokemonImage', favoritePokemonImage);
                        localStorage.setItem('favoritePokemonId', favoritePokemonId);
                    }
                    handleAPIResult({
                        success: true,
                        failed: false,
                        message: 'Success: Login was successful!',
                    });
                    navigate('/eng/ScarletViolet/ScarletViolet');
                })
                .catch((error) => {
                    // console.error('Error during login:', error);
                    handleAPIResult({
                        success: false,
                        failed: true,
                        message: 'Failed: Unable to login.',
                    });
                });
        }
    };

    // Function to handle createUser process
    const createUser = (event: { preventDefault: () => void }) => {
        event.preventDefault();

        // Email validation regex pattern
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Username validation regex pattern, No spaces allowed
        const usernamePattern = /^\S*$/;

        // Password validation regex pattern, No spaces allowed
        const passwordPattern = /^\S*$/;

        if (!username || !password || !email || !favoritePokemon || !countryCode) {
            toast.error(`Error: All fields are required`, {
                autoClose: 1000,
            });
        } else if (!emailPattern.test(email)) {
            toast.error(`Error: Invalid email address`, {
                autoClose: 1000,
            });
        } else if (!usernamePattern.test(username)) {
            toast.error(`Error: Username must contain only alphanumeric characters`, {
                autoClose: 1000,
            });
        } else if (!passwordPattern.test(password)) {
            toast.error(`Error: Password must not contain spaces`, {
                autoClose: 1000,
            });
        } else {
            const body = {
                username,
                email,
                password,
                favoritePokemon: favoritePokemon.id,
                countryCode,
            };

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            };

            fetch('/api/createUser/', options)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .then((result) =>
                    handleAPIResult({
                        success: true,
                        failed: false,
                        message: 'Success: New User added successfully',
                    })
                )
                .then(() => setLoginFormState('HaveAccount'))
                .catch((error) =>
                    handleAPIResult({
                        success: false,
                        failed: true,
                        message:
                            error.message ||
                            'Error: An error occurred while creating the user account',
                    })
                );
        }
    };

    // Function to handle createUser process
    const sendResetPassword = (event: { preventDefault: () => void }) => {
        event.preventDefault();

        if (!email) {
            toast.error(`Error: email is blank`, {
                autoClose: 1000,
            });
        } else {
            const body = {
                email,
            };

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            };

            fetch('/api/forgotPassword/', options)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .then((result) =>
                    handleAPIResult({
                        success: true,
                        failed: false,
                        message:
                            'Check your email. If an account was found a email should arrive soon.',
                    })
                )
                .then(() => setLoginFormState('NeedAccount'))
                .catch((error) =>
                    handleAPIResult({
                        success: false,
                        failed: true,
                        message: 'Error: An error occurred while resetting the password',
                    })
                );
        }
    };

    const newCreateAccountForm = (
        <div className="form-container">
            <section className="login-section">
                <form className="login-form">
                    <LazyLoadImage
                        className="cardTemplate-card-image"
                        src={'https://mypokellection.com/ImageAssets/Hello.png'}
                        alt={'Hello'}
                        effect="blur"
                        width="100%"
                        height="100%"
                        style={{
                            maxWidth: '400px',
                            maxHeight: '400px',
                        }}
                    />
                    <h2 className="login-h2">Sign Up!</h2>
                    <fieldset className="login-fieldset">
                        <legend className="login-legend">Create Account</legend>
                        <ul className="login-ul">
                            <li className="login-li">
                                <label className="login-label" htmlFor="username">
                                    Username:
                                </label>
                                <input
                                    className="login-input"
                                    type="username"
                                    id="username"
                                    value={username}
                                    onChange={(event) => setUsername(event.target.value)}
                                />
                            </li>
                            <li className="login-li">
                                <label className="login-label" htmlFor="email">
                                    Email:
                                </label>
                                <input
                                    className="login-input"
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                            </li>
                            <li className="login-li">
                                <label className="login-label" htmlFor="password">
                                    Password:
                                </label>
                                <input
                                    className="login-input"
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                            </li>
                            <li className="login-li">
                                <Autocomplete
                                    id="favorite-pokemon"
                                    options={pokedex}
                                    groupBy={(option) => `Generation ${option.generation}`}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <li
                                            {...props}
                                            style={{ display: 'flex', alignItems: 'center' }}
                                        >
                                            <img
                                                src={option.image}
                                                alt={option.name}
                                                width="50"
                                                height="50"
                                                style={{ marginRight: 15 }}
                                            />
                                            <span style={{ fontSize: '16px' }}>
                                                {option.number} - {option.name}
                                            </span>
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Favorite Pokémon"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {loading ? (
                                                            <CircularProgress
                                                                color="inherit"
                                                                size={20}
                                                            />
                                                        ) : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    )}
                                    value={autocompleteValue}
                                    onChange={(event, newValue) => {
                                        setAutocompleteValue(newValue);
                                        setFavoritePokemon(newValue);
                                    }}
                                    onClick={(event) => event.stopPropagation()}
                                    loading={loading}
                                    ListboxProps={{
                                        style: { maxHeight: '300px' },
                                    }}
                                />
                            </li>
                            <li className="login-li">
                                <label className="login-label" htmlFor="country">
                                    Country:
                                </label>
                                <div className="country-selector-wrapper">
                                    <CountrySelector
                                        onChange={(code) => setCountryCode(code)}
                                        value={countryCode}
                                    />
                                </div>
                            </li>
                        </ul>
                    </fieldset>
                    <button onClick={createUser} className="login-button">
                        Submit
                    </button>
                    <button onClick={handleHaveAccountClick} className="login-button" type="button">
                        Have an Account?
                    </button>
                    <button onClick={handleForgotPassword} className="login-button" type="button">
                        Forgot Password?
                    </button>
                </form>
            </section>
        </div>
    );

    const newLoginForm = (
        <section className="login-section">
            <form className="login-form">
                <LazyLoadImage
                    className="cardTemplate-card-image"
                    src={'https://mypokellection.com/ImageAssets/Welcome.png'}
                    alt={'Hello'}
                    effect="blur"
                    width="100%"
                    height="100%"
                    style={{
                        maxWidth: '400px',
                        maxHeight: '400px',
                    }}
                />
                <h2 className="login-h2">Login!</h2>
                <fieldset className="login-fieldset">
                    <legend className="login-legend">Login with your credentials</legend>
                    <ul className="login-ul">
                        <li className="login-li">
                            <label className="login-label" htmlFor="email">
                                Email:
                            </label>
                            <input
                                className="login-input"
                                type="email"
                                id="email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                        </li>
                        <li className="login-li">
                            <label className="login-label" htmlFor="password">
                                Password:
                            </label>
                            <input
                                className="login-input"
                                type="password"
                                id="password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                        </li>
                    </ul>
                </fieldset>
                <button onClick={login} className="login-button">
                    Submit
                </button>
                <button onClick={handleNeedAccountClick} className="login-button" type="button">
                    Need an Account?
                </button>
                <button onClick={handleForgotPassword} className="login-button" type="button">
                    Forgot Password?
                </button>
            </form>
        </section>
    );

    const newLogoutForm = (
        <section className="login-section">
            <form className="login-form">
                <LazyLoadImage
                    className="cardTemplate-card-image"
                    src={'https://mypokellection.com/ImageAssets/Goodbye.png'}
                    alt={'Goodbye'}
                    effect="blur"
                    width="100%"
                    height="100%"
                    style={{
                        maxWidth: '400px',
                    }}
                />
                <h2 className="login-h2">Logout!</h2>
                <button onClick={handleLogoutClick} className="login-button">
                    Submit
                </button>
            </form>
        </section>
    );

    const forgotPasswordForm = (
        <section className="login-section">
            <form className="login-form">
                <LazyLoadImage
                    className="cardTemplate-card-image"
                    src={'https://mypokellection.com/ImageAssets/Forgot.png'}
                    alt={'Forgot'}
                    effect="blur"
                    width="100%"
                    height="100%"
                    style={{
                        maxWidth: '400px',
                        maxHeight: '400px',
                    }}
                />
                <h2 className="login-h2">Forgot Password</h2>
                <fieldset className="login-fieldset">
                    <legend className="login-legend">Forgot Password</legend>
                    <ul className="login-ul">
                        <li className="login-li">
                            <label className="login-label" htmlFor="email">
                                Email:
                            </label>
                            <input
                                className="login-input"
                                type="email"
                                id="email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                        </li>
                    </ul>
                </fieldset>
                <button onClick={sendResetPassword} className="login-button">
                    Submit
                </button>
                <button onClick={handleHaveAccountClick} className="login-button" type="button">
                    Have an Account?
                </button>
                <button onClick={handleNeedAccountClick} className="login-button" type="button">
                    Need an Account?
                </button>
            </form>
        </section>
    );

    return (
        <>
            <ToastContainer
                position="top-right"
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            {loginFormState === 'NeedAccount' && authenticatedStorage !== 'true' ? (
                newCreateAccountForm
            ) : (
                <></>
            )}
            {loginFormState === 'HaveAccount' && authenticatedStorage !== 'true' ? (
                newLoginForm
            ) : (
                <></>
            )}
            {loginFormState === 'ForgotPassword' && authenticatedStorage !== 'true' ? (
                forgotPasswordForm
            ) : (
                <></>
            )}
            {authenticatedStorage === 'true' ? newLogoutForm : <></>}
        </>
    );
};

export default LoginPage;
