import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import * as followApi from '../api/followApi';

export const useFollowFunctions = (userName: string | undefined) => {
    const [isFollowing, setIsFollowing] = useState(false);
    const [followerCount, setFollowerCount] = useState(0);
    const [followLoading, setFollowLoading] = useState(false);

    const { isAuthenticated, authParams } = useAuth();
    const currentUserName = localStorage.getItem('userName') || '';

    const checkFollowStatus = useCallback(async () => {
        if (!userName || userName === currentUserName || !isAuthenticated || !authParams) return;

        setFollowLoading(true);
        try {
            const result = await followApi.checkFollowStatus(authParams, userName, currentUserName);
            if (result.success && result.isFollowing !== undefined) {
                setIsFollowing(result.isFollowing);
            }
        } finally {
            setFollowLoading(false);
        }
    }, [userName, currentUserName, isAuthenticated, authParams]);

    const fetchFollowerCount = useCallback(async () => {
        if (!userName) return;

        setFollowLoading(true);
        try {
            const result = await followApi.getFollowersCount(userName);
            if (result.success && result.followerCount !== undefined) {
                setFollowerCount(result.followerCount);
            }
        } finally {
            setFollowLoading(false);
        }
    }, [userName]);

    useEffect(() => {
        checkFollowStatus();
        fetchFollowerCount();
    }, [checkFollowStatus, fetchFollowerCount]);

    const handleFollowUser = async () => {
        if (!authParams || !userName) return;
        setFollowLoading(true);
        try {
            const result = await followApi.followUser(authParams, userName, currentUserName);
            if (result.success) {
                setIsFollowing(true);
                setFollowerCount((prevCount) => prevCount + 1);
            }
        } finally {
            setFollowLoading(false);
        }
    };

    const handleUnfollowUser = async () => {
        if (!authParams || !userName) return;
        setFollowLoading(true);
        try {
            const result = await followApi.unfollowUser(authParams, userName, currentUserName);
            if (result.success) {
                setIsFollowing(false);
                setFollowerCount((prevCount) => prevCount - 1);
            }
        } finally {
            setFollowLoading(false);
        }
    };

    return {
        isFollowing,
        followerCount,
        followLoading,
        handleFollowUser,
        handleUnfollowUser,
    };
};
