import React from 'react';
import {
    Box,
    Grid,
    Paper,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    LinearProgress,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Chip,
    Avatar,
    Stack,
} from '@mui/material';
import {
    People,
    CreditCard,
    BrokenImage,
    Collections,
    Star,
    ArrowUpward,
    ArrowDownward,
    LocalFireDepartment,
    Water,
    Park,
    ElectricBolt,
    Psychology,
    Favorite,
    DarkMode,
    AutoFixHigh,
    Air,
    Foundation,
    BakeryDining,
} from '@mui/icons-material';
import assets from '../../../assets';

const AdminDashboard: React.FC = () => {
    // Mock data
    const totalUsers = Math.floor(Math.random() * 10000) + 5000;
    const totalCards = Math.floor(Math.random() * 100000) + 50000;
    const missingImages = Math.floor(Math.random() * 1000) + 100;
    const activeCollectors = Math.floor(Math.random() * 5000) + 2000;

    const rarityDistribution = [
        { name: 'Common', value: 45, color: '#4CAF50' },
        { name: 'Uncommon', value: 30, color: '#2196F3' },
        { name: 'Rare', value: 15, color: '#9C27B0' },
        { name: 'Ultra Rare', value: 7, color: '#FF9800' },
        { name: 'Secret Rare', value: 3, color: '#F44336' },
    ];

    const recentActivity = Array.from({ length: 8 }, (_, i) => ({
        user: `User_${Math.floor(Math.random() * 1000)}`,
        pokemon: {
            name: [
                'Charizard',
                'Pikachu',
                'Mewtwo',
                'Lugia',
                'Rayquaza',
                'Mew',
                'Blastoise',
                'Venusaur',
            ][Math.floor(Math.random() * 8)],
            number: `${Math.floor(Math.random() * 200)}/200`,
            image: `https://mypokellection.com/Pokedex/${Math.floor(Math.random() * 200) + 1}.png`,
            set: ['Scarlet & Violet', 'Crown Zenith', 'Silver Tempest'][
                Math.floor(Math.random() * 3)
            ],
        },
        date: new Date(
            Date.now() - Math.floor(Math.random() * 7) * 24 * 60 * 60 * 1000
        ).toLocaleDateString(),
        price: (Math.random() * 100 + 1).toFixed(2),
        trend: Math.random() > 0.5,
    }));

    const setDistribution = [
        { name: 'Scarlet & Violet', total: 234, collected: 198, percentage: 85 },
        { name: 'Crown Zenith', total: 159, collected: 142, percentage: 89 },
        { name: 'Silver Tempest', total: 195, collected: 155, percentage: 79 },
        { name: 'Lost Origin', total: 247, collected: 183, percentage: 74 },
        { name: 'Pokémon GO', total: 88, collected: 78, percentage: 89 },
    ];

    const collectionSummary = [
        { category: 'Regular Cards', count: 3456, percentage: 75 },
        { category: 'Reverse Holos', count: 1234, percentage: 65 },
        { category: 'Holos', count: 789, percentage: 55 },
        { category: 'Ultra Rares', count: 234, percentage: 45 },
        { category: 'Secret Rares', count: 89, percentage: 35 },
    ];

    const pokemonTypes = [
        { type: 'Fire', count: 456, icon: <LocalFireDepartment />, color: '#FF7043' },
        { type: 'Water', count: 523, icon: <Water />, color: '#29B6F6' },
        { type: 'Grass', count: 489, icon: <Park />, color: '#66BB6A' },
        { type: 'Electric', count: 234, icon: <ElectricBolt />, color: '#FDD835' },
        { type: 'Psychic', count: 345, icon: <Psychology />, color: '#AB47BC' },
        { type: 'Fighting', count: 267, icon: <Favorite />, color: '#8D6E63' },
        { type: 'Dark', count: 289, icon: <DarkMode />, color: '#424242' },
        { type: 'Fairy', count: 178, icon: <AutoFixHigh />, color: '#EC407A' },
        { type: 'Flying', count: 245, icon: <Air />, color: '#90A4AE' },
        { type: 'Ground', count: 198, icon: <Foundation />, color: '#795548' },
        { type: 'Dragon', count: 156, icon: <BakeryDining />, color: '#7E57C2' },
    ];

    const seriesOverview = [
        {
            name: 'Scarlet & Violet Series',
            logo: assets.english.images.ScarletVioletLogo, // Adjust path as needed
            totalSets: 10,
            totalCards: 2345,
            collectedCards: 1890,
            recentSets: [
                { name: 'Temporal Forces', completion: 85 },
                { name: 'Paradox Rift', completion: 92 },
                { name: 'Paldean Fates', completion: 78 },
            ],
            mostWanted: 'Iono (Secret)',
            averageCompletion: 87,
        },
        {
            name: 'Sword & Shield Series',
            logo: assets.english.images.SwordShieldLogo, // Adjust path as needed
            totalSets: 12,
            totalCards: 3456,
            collectedCards: 3100,
            recentSets: [
                { name: 'Crown Zenith', completion: 95 },
                { name: 'Silver Tempest', completion: 98 },
                { name: 'Lost Origin', completion: 92 },
            ],
            mostWanted: 'Charizard VMAX (Secret)',
            averageCompletion: 93,
        },
        {
            name: 'Sun & Moon Series',
            logo: assets.english.images.SunMoonLogo, // Adjust path as needed
            totalSets: 12,
            totalCards: 3789,
            collectedCards: 3200,
            recentSets: [
                { name: 'Cosmic Eclipse', completion: 96 },
                { name: 'Hidden Fates', completion: 89 },
                { name: 'Team Up', completion: 94 },
            ],
            mostWanted: 'Lillie (Full Art)',
            averageCompletion: 91,
        },
    ];

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" sx={{ mb: 3 }}>
                Admin Dashboard
            </Typography>

            {/* Summary Cards */}
            <Grid container spacing={2} sx={{ mb: 3 }}>
                {[
                    {
                        title: 'Total Users',
                        value: totalUsers.toLocaleString(),
                        icon: <People />,
                        color: '#0088FE',
                    },
                    {
                        title: 'Total Cards',
                        value: totalCards.toLocaleString(),
                        icon: <CreditCard />,
                        color: '#00C49F',
                    },
                    {
                        title: 'Missing Images',
                        value: missingImages.toLocaleString(),
                        icon: <BrokenImage />,
                        color: '#FFBB28',
                    },
                    {
                        title: 'Active Collectors',
                        value: activeCollectors.toLocaleString(),
                        icon: <Collections />,
                        color: '#FF8042',
                    },
                ].map((item) => (
                    <Grid item xs={12} sm={6} md={3} key={item.title}>
                        <Paper
                            sx={{
                                p: 3,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                bgcolor: 'white',
                            }}
                            elevation={4}
                        >
                            <Box>
                                <Typography color="textSecondary" variant="subtitle2">
                                    {item.title}
                                </Typography>
                                <Typography variant="h4" sx={{ mt: 1 }}>
                                    {item.value}
                                </Typography>
                            </Box>
                            <Box sx={{ color: item.color }}>{item.icon}</Box>
                        </Paper>
                    </Grid>
                ))}
            </Grid>

            {/* Main Content */}
            <Grid container spacing={2}>
                {/* Rarity Distribution */}
                <Grid item xs={12} md={8}>
                    <Paper sx={{ p: 3 }} elevation={4}>
                        <Typography variant="h6" sx={{ mb: 3 }}>
                            Card Rarity Distribution
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                            {rarityDistribution.map((rarity) => (
                                <Box key={rarity.name} sx={{ mb: 2 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            mb: 1,
                                        }}
                                    >
                                        <Typography variant="body2">{rarity.name}</Typography>
                                        <Typography variant="body2">{rarity.value}%</Typography>
                                    </Box>
                                    <LinearProgress
                                        variant="determinate"
                                        value={rarity.value}
                                        sx={{
                                            height: 8,
                                            borderRadius: 5,
                                            backgroundColor: `${rarity.color}20`,
                                            '& .MuiLinearProgress-bar': {
                                                backgroundColor: rarity.color,
                                            },
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Paper>
                </Grid>

                {/* Recent Activity */}
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 3, height: '100%' }} elevation={4}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Recent Activity
                        </Typography>
                        <List>
                            {recentActivity.map((activity, index) => (
                                <React.Fragment key={index}>
                                    <ListItem alignItems="flex-start" sx={{ px: 0 }}>
                                        <ListItemAvatar>
                                            <Avatar
                                                variant="rounded"
                                                src={activity.pokemon.image}
                                                sx={{
                                                    width: 60,
                                                    height: 84,
                                                    mr: 1,
                                                    border: 1,
                                                    borderColor: 'grey.300',
                                                }}
                                            />
                                        </ListItemAvatar>
                                        <Box sx={{ ml: 1, width: '100%' }}>
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Typography variant="subtitle2" color="primary">
                                                    {activity.pokemon.name}
                                                </Typography>
                                                <Typography variant="body2" color={'success.main'}>
                                                    ${activity.price}
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                sx={{ mt: 0.5 }}
                                            >
                                                <Typography
                                                    variant="caption"
                                                    color="text.secondary"
                                                >
                                                    {activity.pokemon.number} •{' '}
                                                    {activity.pokemon.set}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    color="text.secondary"
                                                >
                                                    {activity.date}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                    </ListItem>
                                    {index < recentActivity.length - 1 && (
                                        <Divider variant="inset" component="li" sx={{ ml: 0 }} />
                                    )}
                                </React.Fragment>
                            ))}
                        </List>
                    </Paper>
                </Grid>

                {/* Collection Stats */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 3 }} elevation={4}>
                        <Typography variant="h6" sx={{ mb: 3 }}>
                            Collection Completion Stats
                        </Typography>
                        <Grid container spacing={2}>
                            {[
                                { label: 'Complete Sets', value: '15%', color: '#4CAF50' },
                                { label: 'Near Complete', value: '25%', color: '#2196F3' },
                                { label: 'In Progress', value: '35%', color: '#FF9800' },
                                { label: 'Just Started', value: '25%', color: '#F44336' },
                            ].map((stat) => (
                                <Grid item xs={12} sm={6} md={3} key={stat.label}>
                                    <Box sx={{ textAlign: 'center', p: 2 }}>
                                        <Typography variant="h4" sx={{ color: stat.color, mb: 1 }}>
                                            {stat.value}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {stat.label}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                </Grid>

                {/* Set Distribution */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 3 }} elevation={4}>
                        <Typography variant="h6" sx={{ mb: 3 }}>
                            Recent Set Completion
                        </Typography>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Set Name</TableCell>
                                        <TableCell align="right">Progress</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {setDistribution.map((set) => (
                                        <TableRow key={set.name}>
                                            <TableCell>{set.name}</TableCell>
                                            <TableCell align="right">
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: 1,
                                                    }}
                                                >
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={set.percentage}
                                                        sx={{
                                                            flexGrow: 1,
                                                            height: 8,
                                                            borderRadius: 5,
                                                        }}
                                                    />
                                                    <Typography variant="body2">
                                                        {set.percentage}%
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Chip
                                                    label={`${set.collected}/${set.total}`}
                                                    size="small"
                                                    color={
                                                        set.percentage > 80 ? 'success' : 'primary'
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>

                {/* Collection Summary */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 3 }} elevation={4}>
                        <Typography variant="h6" sx={{ mb: 3 }}>
                            Collection Summary
                        </Typography>
                        {collectionSummary.map((item) => (
                            <Box key={item.category} sx={{ mb: 2 }}>
                                <Box
                                    sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}
                                >
                                    <Typography variant="body2">{item.category}</Typography>
                                    <Typography variant="body2">
                                        {item.count.toLocaleString()} cards
                                    </Typography>
                                </Box>
                                <LinearProgress
                                    variant="determinate"
                                    value={item.percentage}
                                    sx={{
                                        height: 8,
                                        borderRadius: 5,
                                    }}
                                />
                            </Box>
                        ))}
                    </Paper>
                </Grid>

                {/* Pokemon Type Distribution */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 3 }} elevation={4}>
                        <Typography variant="h6" sx={{ mb: 3 }}>
                            Pokémon Type Distribution
                        </Typography>
                        <Grid container spacing={2}>
                            {pokemonTypes.map((type) => (
                                <Grid item xs={6} sm={4} md={2} key={type.type}>
                                    <Paper
                                        elevation={2}
                                        sx={{
                                            p: 2,
                                            textAlign: 'center',
                                            bgcolor: `${type.color}15`,
                                            border: 1,
                                            borderColor: `${type.color}30`,
                                        }}
                                    >
                                        <Box sx={{ color: type.color }}>{type.icon}</Box>
                                        <Typography variant="h6" sx={{ color: type.color, my: 1 }}>
                                            {type.count}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {type.type}
                                        </Typography>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                </Grid>

                {/* Series Overview */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 3 }} elevation={4}>
                        <Typography variant="h6" sx={{ mb: 3 }}>
                            Series Overview
                        </Typography>
                        <Grid container spacing={3}>
                            {seriesOverview.map((series) => (
                                <Grid item xs={12} md={4} key={series.name}>
                                    <Paper
                                        elevation={2}
                                        sx={{
                                            p: 2,
                                            height: '100%',
                                            bgcolor: 'background.default',
                                            border: 1,
                                            borderColor: 'divider',
                                        }}
                                    >
                                        {/* Series Logo */}
                                        <Box
                                            sx={{
                                                mb: 2,
                                                height: 60,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <img
                                                src={series.logo}
                                                alt={series.name}
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                        </Box>

                                        {/* Main Stats */}
                                        <Box sx={{ mb: 2 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                    >
                                                        Total Sets
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {series.totalSets}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                    >
                                                        Completion
                                                    </Typography>
                                                    <Typography variant="h6" color="success.main">
                                                        {Math.round(
                                                            (series.collectedCards /
                                                                series.totalCards) *
                                                                100
                                                        )}
                                                        %
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* Recent Sets */}
                                        <Typography
                                            variant="subtitle2"
                                            color="text.secondary"
                                            sx={{ mb: 1 }}
                                        >
                                            Recent Sets
                                        </Typography>
                                        {series.recentSets.map((set) => (
                                            <Box key={set.name} sx={{ mb: 1 }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        mb: 0.5,
                                                    }}
                                                >
                                                    <Typography variant="body2">
                                                        {set.name}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="success.main"
                                                    >
                                                        {set.completion}%
                                                    </Typography>
                                                </Box>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={set.completion}
                                                    sx={{
                                                        height: 4,
                                                        borderRadius: 2,
                                                        bgcolor: 'rgba(0,0,0,0.05)',
                                                    }}
                                                />
                                            </Box>
                                        ))}

                                        {/* Additional Stats */}
                                        <Box
                                            sx={{
                                                mt: 2,
                                                pt: 2,
                                                borderTop: 1,
                                                borderColor: 'divider',
                                            }}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                    >
                                                        Total Cards
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        {series.collectedCards.toLocaleString()} /{' '}
                                                        {series.totalCards.toLocaleString()}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AdminDashboard;
