import React, { useState } from 'react';
import { Typography, Avatar, Box, Grid, Paper, IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface FavoritePokemonProps {
    favoritePokemon: {
        name: string;
        image: string;
        number: number;
        generation: number;
        height: number;
        weight: number;
        number_featured_cards: number;
        number_cameo_cards: number;
    };
    favoritePokemonCardCount: number;
    favoritePokemonFeaturedCardCount: number;
    favoritePokemonCameoCardCount: number;
    collectedFavoritePokemonCardCount: number;
    collectedFavoritePokemonFeaturedCardCount: number;
    collectedFavoritePokemonCameoCardCount: number;
}

const DesktopFavoritePokemon: React.FC<FavoritePokemonProps> = (props) => {
    const [expanded, setExpanded] = useState(true);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <Paper elevation={4} style={{ width: '100%', padding: '16px' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <Box display="flex" alignItems="center">
                    <Typography variant="h6" mr={2}>
                        Favorite Pokémon
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                        Generation: {props.favoritePokemon.generation} |{' '}
                        {props.favoritePokemon.name} #{props.favoritePokemon.number}
                    </Typography>
                </Box>
                {/* <IconButton onClick={toggleExpanded} size="small">
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton> */}
            </Box>
            {/* <Collapse in={expanded}> */}
            <Box display="flex" alignItems="center">
                <Avatar
                    src={props.favoritePokemon.image}
                    alt={props.favoritePokemon.name}
                    sx={{ width: 80, height: 80, mr: 2 }}
                />
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <Typography variant="body2">Total Cards</Typography>
                        <Typography variant="body1">
                            {props.collectedFavoritePokemonCardCount}/
                            {props.favoritePokemonCardCount}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body2">Featured Cards</Typography>
                        <Typography variant="body1">
                            {props.collectedFavoritePokemonFeaturedCardCount}/
                            {props.favoritePokemonFeaturedCardCount}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body2">Cameo Cards</Typography>
                        <Typography variant="body1">
                            {props.collectedFavoritePokemonCameoCardCount}/
                            {props.favoritePokemonCameoCardCount}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            {/* </Collapse> */}
        </Paper>
    );
};

export default DesktopFavoritePokemon;
