import * as React from 'react';
import { Link } from 'react-router-dom';
import { RouteType } from '../../routes/config';
import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';
import appRoutes from '../../routes/appRoutes';
import {
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    List,
    useMediaQuery,
    Stack,
    Grid,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import colorConfigs from '../../configs/colorConfigs';
import sizeConfigs from '../../configs/sizeConfigs';
import assets from '../../assets';
import Avatar from '@mui/material/Avatar';
import '../../css/sideBar.css';

const Topbar = () => {
    const tcgState = useSelector((state: RootState) => state.TCGState);
    const authenticatedStorage = localStorage.getItem('authenticated');
    const authenticated = useSelector((state: RootState) => state.authenticated);
    const userName = localStorage.getItem('userName');

    const isMobile = useMediaQuery('(max-width:788px)');
    const isLargeScreen = useMediaQuery('(min-width:789px)');

    const [openDrawer, setOpenDrawer] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    function filterRoutes(routes: RouteType[]) {
        return routes.filter((route) => {
            if (route.beta) {
                return false;
            }

            if (route.child) {
                route.child = filterRoutes(route.child);
            }

            if (authenticated || authenticatedStorage === 'true') {
                return (
                    route.sidebarProps &&
                    (route.tcgState === 'All' ||
                        route.tcgState === tcgState ||
                        route.state === 'Logout')
                );
            } else {
                return (
                    route.sidebarProps &&
                    (route.tcgState === 'All' ||
                        route.tcgState === tcgState ||
                        route.state === 'Login')
                );
            }
        });
    }

    let filteredRoutes = filterRoutes(appRoutes);

    return (
        <React.Fragment>
            {!isLargeScreen && (
                <AppBar
                    position="fixed"
                    sx={{
                        width: '100%',
                        marginLeft: 0,
                        boxShadow: 'unset',
                        backgroundColor: colorConfigs.topbar.bg,
                        color: colorConfigs.topbar.color,
                        '@media (min-width: 789px)': {
                            width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                            marginLeft: sizeConfigs.sidebar.width,
                        },
                    }}
                >
                    <Toolbar>
                        <Grid container alignItems="center">
                            <Grid item xs={3} textAlign="left">
                                {/* Logo on the far left */}
                                <img
                                    style={{ width: 'auto', height: 'auto', maxWidth: '50px' }}
                                    src={assets.site.images.logo}
                                    alt="logo"
                                />
                            </Grid>
                            <Grid item xs={6} textAlign="center">
                                {/* Centered menu */}
                                {isMobile && (
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={handleDrawerOpen}
                                        sx={{
                                            backgroundColor: colorConfigs.topbar.bg,
                                            color: colorConfigs.topbar.color,
                                        }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                )}
                            </Grid>
                            <Grid item xs={3} textAlign="right">
                                <img
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        maxWidth: '100px',
                                        paddingRight: '10px',
                                    }}
                                    src={assets.site.images.logo2}
                                    alt="logo"
                                />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            )}

            <Drawer
                variant={isMobile ? 'temporary' : 'permanent'}
                open={isMobile ? openDrawer : true}
                onClose={handleDrawerClose}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    width: sizeConfigs.sidebar.width,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: sizeConfigs.sidebar.width,
                        boxSizing: 'border-box',
                        borderRight: '0px',
                        backgroundColor: colorConfigs.sidebar.bg,
                        color: colorConfigs.sidebar.color,
                    },
                }}
            >
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerClose}
                    sx={{
                        marginRight: 2,
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <List disablePadding>
                    <Toolbar sx={{ marginBottom: '20px' }}>
                        <Stack sx={{ width: '100%' }} direction="row" justifyContent="center">
                            <Link to="/">
                                <img
                                    style={{ width: 'auto', height: 'auto', maxWidth: '100px' }}
                                    src={assets.site.images.logo}
                                    alt=""
                                ></img>
                            </Link>
                        </Stack>
                    </Toolbar>
                    <div className="sidebar-userProfile">
                        <Avatar alt={userName ?? 'Guest'} src="/static/images/avatar/1.jpg" />
                        <p>{userName ?? 'Guest'}</p>
                    </div>
                    {filteredRoutes.map((route, index) =>
                        route.sidebarProps ? (
                            route.child ? (
                                <SidebarItemCollapse item={route} key={index} />
                            ) : (
                                <SidebarItem item={route} key={index} />
                            )
                        ) : null
                    )}
                </List>
                <div>
                    <Link className="privacypolicy" to="/PrivacyPolicy">
                        Privacy Policy
                    </Link>
                </div>
                <div>
                    <p className="footer-text">
                        MyPokellection is not affiliated with, sponsored or endorsed by, or in any
                        way associated with Pokemon or The Pokemon Company International Inc
                    </p>
                </div>
            </Drawer>
        </React.Fragment>
    );
};

export default Topbar;
