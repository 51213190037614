import { createReducer } from '@reduxjs/toolkit';

const initialprintRunState = 'None';

const printRunReducer = createReducer(initialprintRunState, {
    SET_PRINTRUN: (state, action) => {
        return action.payload;
    },
});

export default printRunReducer;
