import { RouteType } from './config';
import assets from '../assets';
import '../css/index.css';
import '../css/setInfoUsedOnOverViewPages.css';

import * as allEnglishSets from '../pages/English/Data/SetInfo';
import TemplateSet from '../pages/English/Template/TemplateSet';
import TemplateIndex from '../pages/English/Template/TemplateIndex';
import TemplateLayout from '../pages/English/Template/TemplateLayout';

const englishAppRoutes: RouteType[] = [
    {
        path: '/eng/ScarletViolet',
        element: <TemplateLayout />,
        state: 'englishScarletViolet',
        tcgState: 'English',
        beta: false,
        sidebarProps: {
            displayText: 'Scarlet & Violet Series',
        },
        child: [
            {
                index: true,
                element: <TemplateIndex />,
                state: 'englishScarletVioletSeries.index',
                tcgState: 'English',
                beta: false,
            },
            {
                path: '/eng/ScarletViolet/ScarletViolet',
                element: <TemplateSet setInfo={allEnglishSets.ScarletViolet} />,
                state: 'englishScarletVioletSeries.ScarletViolet',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Scarlet & Violet',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.ScarletVioletIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/ScarletViolet/PaldeaEvolved',
                element: <TemplateSet setInfo={allEnglishSets.PaldeaEvolved} />,
                state: 'englishScarletVioletSeries.PaldeaEvolved',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Paldea Evolved',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.PaldeaEvolvedIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/ScarletViolet/ObsidianFlames',
                element: <TemplateSet setInfo={allEnglishSets.ObsidianFlames} />,
                state: 'englishScarletVioletSeries.Obsidian Flames',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Obsidian Flames',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.ObsidianFlamesIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/ScarletViolet/151',
                element: <TemplateSet setInfo={allEnglishSets.ScarletViolet151} />,
                state: 'englishScarletVioletSeries.151',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: '151',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.ScarletViolet151Icon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/ScarletViolet/ParadoxRift',
                element: <TemplateSet setInfo={allEnglishSets.ParadoxRift} />,
                state: 'englishScarletVioletSeries.ParadoxRift',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Paradox Rift',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.ParadoxRiftIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/ScarletViolet/PaldeanFates',
                element: <TemplateSet setInfo={allEnglishSets.PaldeanFates} />,
                state: 'englishScarletVioletSeries.PaldeanFates',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Paldean Fates',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.PaldeanFatesIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/ScarletViolet/TemporalForces',
                element: <TemplateSet setInfo={allEnglishSets.TemporalForces} />,
                state: 'englishScarletVioletSeries.TemporalForces',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Temporal Forces',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.TemporalForcesIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/ScarletViolet/TwilightMasquerade',
                element: <TemplateSet setInfo={allEnglishSets.TwilightMasquerade} />,
                state: 'englishScarletVioletSeries.TwilightMasquerade',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Twilight Masquerade',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.TwilightMasqueradeIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/ScarletViolet/ShroudedFable',
                element: <TemplateSet setInfo={allEnglishSets.ShroudedFable} />,
                state: 'englishScarletVioletSeries.ShroudedFable',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Shrouded Fable',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.ShroudedFableIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/ScarletViolet/StellarCrown',
                element: <TemplateSet setInfo={allEnglishSets.StellarCrown} />,
                state: 'englishScarletVioletSeries.StellarCrown',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Stellar Crown',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.StellarCrownIcon}
                            alt=""
                        />
                    ),
                },
            },
        ],
    },
    {
        path: '/eng/SwordShield',
        element: <TemplateLayout />,
        state: 'englishSwordShield',
        tcgState: 'English',
        beta: false,
        sidebarProps: {
            displayText: 'Sword & Shield Series',
        },
        child: [
            {
                index: true,
                element: <TemplateIndex />,
                state: 'englishSwordShieldSeries.index',
                tcgState: 'English',
                beta: false,
            },
            {
                path: '/eng/SwordShield/SwordShield',
                element: <TemplateSet setInfo={allEnglishSets.SwordShield} />,
                state: 'englishSwordShieldSeries.SwordShield',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Sword & Shield',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.SwordShieldIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SwordShield/RebelClash',
                element: <TemplateSet setInfo={allEnglishSets.RebelClash} />,
                state: 'englishSwordShieldSeries.RebelClash',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Rebel Clash',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.RebelClashIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SwordShield/DarknessAblaze',
                element: <TemplateSet setInfo={allEnglishSets.DarknessAblaze} />,
                state: 'englishSwordShieldSeries.DarknessAblaze',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Darkness Ablaze',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.DarknessAblazeIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SwordShield/ChampionsPath',
                element: <TemplateSet setInfo={allEnglishSets.ChampionsPath} />,
                state: 'englishSwordShieldSeries.ChampionsPath',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Champions Path',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.ChampionsPathIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SwordShield/VividVoltage',
                element: <TemplateSet setInfo={allEnglishSets.VividVoltage} />,
                state: 'englishSwordShieldSeries.VividVoltage',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Vivid Voltage',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.VividVoltageIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SwordShield/ShiningFates',
                element: <TemplateSet setInfo={allEnglishSets.ShiningFates} />,
                state: 'englishSwordShieldSeries.ShiningFates',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Shining Fates',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.ShiningFatesIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SwordShield/BattleStyles',
                element: <TemplateSet setInfo={allEnglishSets.BattleStyles} />,
                state: 'englishSwordShieldSeries.BattleStyles',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Battle Styles',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.BattleStylesIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SwordShield/ChillingReign',
                element: <TemplateSet setInfo={allEnglishSets.ChillingReign} />,
                state: 'englishSwordShieldSeries.ChillingReign',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Chilling Reign',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.ChillingReignIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SwordShield/EvolvingSkies',
                element: <TemplateSet setInfo={allEnglishSets.EvolvingSkies} />,
                state: 'englishSwordShieldSeries.EvolvingSkies',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Evolving Skies',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EvolvingSkiesIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SwordShield/Celebrations',
                element: <TemplateSet setInfo={allEnglishSets.Celebrations} />,
                state: 'englishSwordShieldSeries.Celebrations',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Celebrations',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.CelebrationsIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SwordShield/FusionStrike',
                element: <TemplateSet setInfo={allEnglishSets.FusionStrike} />,
                state: 'englishSwordShieldSeries.FusionStrike',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Fusion Strike',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.FusionStrikeIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SwordShield/BrilliantStars',
                element: <TemplateSet setInfo={allEnglishSets.BrilliantStars} />,
                state: 'englishSwordShieldSeries.BrilliantStars',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Brilliant Stars',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.BrilliantStarsIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SwordShield/AstralRadiance',
                element: <TemplateSet setInfo={allEnglishSets.AstralRadiance} />,
                state: 'englishSwordShieldSeries.AstralRadiance',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Astral Radiance',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.AstralRadianceIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SwordShield/PokemonGo',
                element: <TemplateSet setInfo={allEnglishSets.PokemonGO} />,
                state: 'englishSwordShieldSeries.PokemonGo',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Pokemon GO',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.PokemonGoIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SwordShield/LostOrigin',
                element: <TemplateSet setInfo={allEnglishSets.LostOrigin} />,
                state: 'englishSwordShieldSeries.LostOrigin',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Lost Origin',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.LostOriginIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SwordShield/SilverTempest',
                element: <TemplateSet setInfo={allEnglishSets.SilverTempest} />,
                state: 'englishSwordShieldSeries.SilverTempest',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Silver Tempest',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.SilverTempestIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SwordShield/CrownZenith',
                element: <TemplateSet setInfo={allEnglishSets.CrownZenith} />,
                state: 'englishSwordShieldSeries.CrownZenith',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Crown Zenith',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.CrownZenithIcon}
                            alt=""
                        />
                    ),
                },
            },
        ],
    },
    {
        path: '/eng/SunMoon',
        element: <TemplateLayout />,
        state: 'englisSunMoon',
        tcgState: 'English',
        beta: false,
        sidebarProps: {
            displayText: 'SunMoon Series',
        },
        child: [
            {
                index: true,
                element: <TemplateIndex />,
                state: 'englishSunMoonSeries.index',
                tcgState: 'English',
                beta: false,
            },
            {
                path: '/eng/SunMoon/SunMoon',
                element: <TemplateSet setInfo={allEnglishSets.SunMoon} />,
                state: 'englishSunMoonSeries.SunMoon',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Sun & Moon',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.SunMoonIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SunMoon/GuardiansRising',
                element: <TemplateSet setInfo={allEnglishSets.GuardiansRising} />,
                state: 'englishSunMoonSeries.GuardiansRising',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Guardians Rising',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.GuardiansRisingIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SunMoon/BurningShadows',
                element: <TemplateSet setInfo={allEnglishSets.BurningShadows} />,
                state: 'englishSunMoonSeries.BurningShadows',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Burning Shadows',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.BurningShadowsIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SunMoon/ShiningLegends',
                element: <TemplateSet setInfo={allEnglishSets.ShiningLegends} />,
                state: 'englishSunMoonSeries.ShiningLegends',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Shining Legends',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.ShiningLegendsIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SunMoon/CrimsonInvasion',
                element: <TemplateSet setInfo={allEnglishSets.CrimsonInvasion} />,
                state: 'englishSunMoonSeries.CrimsonInvasion',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Crimson Invasion',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.CrimsonInvasionIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SunMoon/UltraPrism',
                element: <TemplateSet setInfo={allEnglishSets.UltraPrism} />,
                state: 'englishSunMoonSeries.UltraPrism',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Ultra Prism',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.UltraPrismIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SunMoon/ForbiddenLight',
                element: <TemplateSet setInfo={allEnglishSets.ForbiddenLight} />,
                state: 'englishSunMoonSeries.ForbiddenLight',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Forbidden Light',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.ForbiddenLightIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SunMoon/CelestialStorm',
                element: <TemplateSet setInfo={allEnglishSets.CelestialStorm} />,
                state: 'englishSunMoonSeries.CelestialStorm',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Celestial Storm',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.CelestialStormIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SunMoon/DragonMajesty',
                element: <TemplateSet setInfo={allEnglishSets.DragonMajesty} />,
                state: 'englishSunMoonSeries.DragonMajesty',
                tcgState: 'English',
                beta: true,
                sidebarProps: {
                    displayText: 'Dragon Majesty',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.DragonMajestyIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SunMoon/LostThunder',
                element: <TemplateSet setInfo={allEnglishSets.LostThunder} />,
                state: 'englishSunMoonSeries.LostThunder',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Lost Thunder',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.LostThunderIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SunMoon/TeamUp',
                element: <TemplateSet setInfo={allEnglishSets.TeamUp} />,
                state: 'englishSunMoonSeries.TeamUp',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Team Up',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.TeamUpIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SunMoon/DetectivePikachu',
                element: <TemplateSet setInfo={allEnglishSets.DetectivePikachu} />,
                state: 'englishSunMoonSeries.DetectivePikachu',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Detective Pikachu',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.DetectivePikachuIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SunMoon/UnbrokenBonds',
                element: <TemplateSet setInfo={allEnglishSets.UnbrokenBonds} />,
                state: 'englishSunMoonSeries.UnbrokenBonds',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Unbroken Bonds',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.UnbrokenBondsIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SunMoon/UnifiedMinds',
                element: <TemplateSet setInfo={allEnglishSets.UnifiedMinds} />,
                state: 'englishSunMoonSeries.UnifiedMinds',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Unified Minds',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.UnifiedMindsIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SunMoon/HiddenFates',
                element: <TemplateSet setInfo={allEnglishSets.HiddenFates} />,
                state: 'englishSunMoonSeries.HiddenFates',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Hidden Fates',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.HiddenFatesIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/SunMoon/CosmicEclipse',
                element: <TemplateSet setInfo={allEnglishSets.CosmicEclipse} />,
                state: 'englishSunMoonSeries.CosmicEclipse',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Cosmic Eclipse',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.CosmicEclipseIcon}
                            alt=""
                        />
                    ),
                },
            },
        ],
    },
    {
        path: '/eng/XY',
        element: <TemplateLayout />,
        state: 'englishXY',
        tcgState: 'English',
        beta: false,
        sidebarProps: {
            displayText: 'XY Series',
        },
        child: [
            {
                index: true,
                element: <TemplateIndex />,
                state: 'englishXYSeries.index',
                tcgState: 'English',
                beta: false,
            },
            {
                path: '/eng/XY/XY',
                element: <TemplateSet setInfo={allEnglishSets.XY} />,
                state: 'englishXYSeries.XY',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'XY',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.XYIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/XY/FlashFire',
                element: <TemplateSet setInfo={allEnglishSets.Flashfire} />,
                state: 'englishXYSeries.FlashFire',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Flashfire',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.FlashFireIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/XY/FuriousFists',
                element: <TemplateSet setInfo={allEnglishSets.FuriousFists} />,
                state: 'englishXYSeries.FuriousFists',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Furious Fists',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.FuriousFistsIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/XY/PhantomForces',
                element: <TemplateSet setInfo={allEnglishSets.PhantomForces} />,
                state: 'englishXYSeries.PhantomForces',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Phantom Forces',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.PhantonForcesIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/XY/PrimalClash',
                element: <TemplateSet setInfo={allEnglishSets.PrimalClash} />,
                state: 'englishXYSeries.PrimalClash',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Primal Clash',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.PrimalClashIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/XY/DoubleCrisis',
                element: <TemplateSet setInfo={allEnglishSets.EnglishDefaultSet} />,
                state: 'englishXYSeries.DoubleCrisis',
                tcgState: 'English',
                beta: true,
                sidebarProps: {
                    displayText: 'Double Crisis',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.DoubleCrisisIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/XY/RoaringSkies',
                element: <TemplateSet setInfo={allEnglishSets.RoaringSkies} />,
                state: 'englishXYSeries.RoaringSkies',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'RoaringSkies',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.RoaringSkiesIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/XY/AncientOrigins',
                element: <TemplateSet setInfo={allEnglishSets.AncientOrigins} />,
                state: 'englishXYSeries.AncientOrigins',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Ancient Origins',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.AncientOriginsIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/XY/BreakThrough',
                element: <TemplateSet setInfo={allEnglishSets.BreakThrough} />,
                state: 'englishXYSeries.BreakThrough',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'BREAKThrough',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.BreakThroughIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/XY/BreakPoint',
                element: <TemplateSet setInfo={allEnglishSets.BreakPoint} />,
                state: 'englishXYSeries.BreakPoint',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'BREAKPoint',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.BreakPointIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/XY/EnglishGenerations',
                element: <TemplateSet setInfo={allEnglishSets.EnglishDefaultSet} />,
                state: 'englishXYSeries.Generations',
                tcgState: 'English',
                beta: true,
                sidebarProps: {
                    displayText: 'Generations',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.GenerationsIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/XY/FatesCollide',
                element: <TemplateSet setInfo={allEnglishSets.FatesCollide} />,
                state: 'englishXYSeries.FatesCollide',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Fates Collide',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.FatesCollideIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/XY/SteamSiege',
                element: <TemplateSet setInfo={allEnglishSets.SteamSiege} />,
                state: 'englishXYSeries.SteamSiege',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Steam Siege',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.SteamSiegeIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/XY/Evolutions',
                element: <TemplateSet setInfo={allEnglishSets.Evolutions} />,
                state: 'englishXYSeries.Evolutions',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Evolutions',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EvolutionsIcon}
                            alt=""
                        />
                    ),
                },
            },
        ],
    },
    {
        path: '/eng/BlackWhite',
        element: <TemplateLayout />,
        state: 'englishBlackWhite',
        tcgState: 'English',
        beta: false,
        sidebarProps: {
            displayText: 'Black & White Series',
        },
        child: [
            {
                index: true,
                element: <TemplateIndex />,
                state: 'englishBlackWhiteSeries.index',
                tcgState: 'English',
                beta: false,
            },
            {
                path: '/eng/BlackWhite/BlackWhite',
                element: <TemplateSet setInfo={allEnglishSets.BlackWhite} />,
                state: 'englishBlackWhiteSeries.englishBlackWhite',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Black & White',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.BlackWhiteIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/BlackWhite/EmergingPowers',
                element: <TemplateSet setInfo={allEnglishSets.EmergingPowers} />,
                state: 'englishBlackWhiteSeries.englishEmergingPowers',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Emerging Powers',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EmergingPowersIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/BlackWhite/NobelVictories',
                element: <TemplateSet setInfo={allEnglishSets.NobleVictories} />,
                state: 'englishBlackWhiteSeries.englishNobelVictories',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Nobel Victories',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.NobleVictoriesIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/BlackWhite/NextDestinies',
                element: <TemplateSet setInfo={allEnglishSets.NextDestinies} />,
                state: 'englishBlackWhiteSeries.englishNextDestinies',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Next Destinies',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.NextDestiniesIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/BlackWhite/DarkExplorers',
                element: <TemplateSet setInfo={allEnglishSets.DarkExplorers} />,
                state: 'englishBlackWhiteSeries.englishDarkExplorer',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Dark Explorer',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.DarkExplorersIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/BlackWhite/DragonsExalted',
                element: <TemplateSet setInfo={allEnglishSets.DragonsExalted} />,
                state: 'englishBlackWhiteSeries.englishDragonsExalted',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Dragons Exalted',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.DragonsExaltedIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/BlackWhite/DragonVault',
                element: <TemplateSet setInfo={allEnglishSets.DragonVault} />,
                state: 'englishBlackWhiteSeries.englishDragonVault',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Dragon Vault',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.DragonVaultIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/BlackWhite/BoundariesCrossed',
                element: <TemplateSet setInfo={allEnglishSets.BoundariesCrossed} />,
                state: 'englishBlackWhiteSeries.englishBoundariesCrossed',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Boundaries Crossed',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.BoundariesCrossedIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/BlackWhite/PlasmaStorm',
                element: <TemplateSet setInfo={allEnglishSets.PlasmaStorm} />,
                state: 'englishBlackWhiteSeries.englishPlasmaStorm',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Plasma Storm',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.PlasmaStormIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/BlackWhite/PlasmaFreeze',
                element: <TemplateSet setInfo={allEnglishSets.PlasmaFreeze} />,
                state: 'englishBlackWhiteSeries.englishPlasmaFreeze',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Plasma Freeze',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.PlasmaFreezeIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/BlackWhite/PlasmaBlast',
                element: <TemplateSet setInfo={allEnglishSets.PlasmaBlast} />,
                state: 'englishBlackWhiteSeries.englishPlasmaBlast',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Plasma Blast',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.PlasmaBlastIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/BlackWhite/LegendaryTreasures',
                element: <TemplateSet setInfo={allEnglishSets.LegendaryTreasures} />,
                state: 'englishBlackWhiteSeries.englishLegendaryTreasures',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Legendary Treasures',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.LegendaryTreasuresIcon}
                            alt=""
                        />
                    ),
                },
            },
        ],
    },
    {
        path: '/eng/HeartGoldSoulSilver',
        element: <TemplateLayout />,
        state: 'englishHeartGoldSoulSilver',
        tcgState: 'English',
        beta: false,
        sidebarProps: {
            displayText: 'HeartGoldSoulSilver Series',
        },
        child: [
            {
                index: true,
                element: <TemplateIndex />,
                state: 'englishHeartGoldSoulSilverSeries.index',
                tcgState: 'English',
                beta: false,
            },
            {
                path: '/eng/HeartGoldSoulSilver/EnglishHeartGoldSoulSilver',
                element: <TemplateSet setInfo={allEnglishSets.HeartGoldSoulSilver} />,
                state: 'englishHeartGoldSoulSilverSeries.englishHeartGoldSoulSilver',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'HeartGold SoulSilver',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.heartGoldSoulSilverIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/HeartGoldSoulSilver/EnglishUnleashed',
                element: <TemplateSet setInfo={allEnglishSets.Unleashed} />,
                state: 'englishHeartGoldSoulSilverSeries.englishUnleashed',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Unleashed',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.UnleashedIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/HeartGoldSoulSilver/EnglishUndaunted',
                element: <TemplateSet setInfo={allEnglishSets.Undaunted} />,
                state: 'englishHeartGoldSoulSilverSeries.englishUndaunted',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Undaunted',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.UndauntedIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/HeartGoldSoulSilver/EnglishTriumphant',
                element: <TemplateSet setInfo={allEnglishSets.Triumphant} />,
                state: 'englishHeartGoldSoulSilverSeries.englishTriumphant',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Triumphant',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.TriumphantIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/HeartGoldSoulSilver/EnglishCallofLegends',
                element: <TemplateSet setInfo={allEnglishSets.CallOfLegends} />,
                state: 'englishHeartGoldSoulSilverSeries.englishCallofLegends',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Call of Legends',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.CallofLegendsIcon}
                            alt=""
                        />
                    ),
                },
            },
        ],
    },
    {
        path: '/eng/Platinum',
        element: <TemplateLayout />,
        state: 'englishPlatinum',
        tcgState: 'English',
        beta: false,
        sidebarProps: {
            displayText: 'Platinum Series',
        },
        child: [
            {
                index: true,
                element: <TemplateIndex />,
                state: 'englishPlatinum.index',
                tcgState: 'English',
                beta: false,
            },
            {
                path: '/eng/Platinum/Platinum',
                element: <TemplateSet setInfo={allEnglishSets.Platinum} />,
                state: 'englishPlatinum.Platinum',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Platinum',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.PlatinumIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/Platinum/RisingRivals',
                element: <TemplateSet setInfo={allEnglishSets.RisingRivals} />,
                state: 'englishPlatinum.RisingRivals',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Rising Rivals',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.RisingRivalsIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/Platinum/SupremeVictors',
                element: <TemplateSet setInfo={allEnglishSets.SupremeVictors} />,
                state: 'englishPlatinum.SupremeVictors',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Supreme Victors',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.SupremeVictorsIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/Platinum/Arceus',
                element: <TemplateSet setInfo={allEnglishSets.Arceus} />,
                state: 'englishPlatinum.Arceus',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Arceus',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.ArceusIcon}
                            alt=""
                        />
                    ),
                },
            },
        ],
    },
    {
        path: '/eng/DiamondPearl',
        element: <TemplateLayout />,
        state: 'englishEXDiamondPearl',
        tcgState: 'English',
        beta: false,
        sidebarProps: {
            displayText: 'DiamondPearl Series',
        },
        child: [
            {
                index: true,
                element: <TemplateIndex />,
                state: 'englishDiamondPearlSeries.index',
                tcgState: 'English',
                beta: false,
            },
            {
                path: '/eng/DiamondPearl/DiamondPearl',
                element: <TemplateSet setInfo={allEnglishSets.DiamondPearl} />,
                state: 'englishDiamondPearlSeries.englishDiamondPearl',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Diamond & Pearl',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.DiamondPearlIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/DiamondPearl/MysteriousTreasures',
                element: <TemplateSet setInfo={allEnglishSets.MysteriousTreasures} />,
                state: 'englishDiamondPearlSeries.englishMysteriousTreasures',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Mysterious Treasures',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.MysteriousTreasuresIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/DiamondPearl/SecretWonders',
                element: <TemplateSet setInfo={allEnglishSets.SecretWonders} />,
                state: 'englishDiamondPearlSeries.englishSecretWonders',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Secret Wonders',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.SecretWondersIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/DiamondPearl/GreatEncounters',
                element: <TemplateSet setInfo={allEnglishSets.GreatEncounters} />,
                state: 'englishDiamondPearlSeries.englishGreatEncounters',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Great Encounters',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.GreatEncountersIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/DiamondPearl/MajesticDawn',
                element: <TemplateSet setInfo={allEnglishSets.MajesticDawn} />,
                state: 'englishDiamondPearlSeries.englishMajesticDawn',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Majestic Dawn',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.MajesticDawnIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/DiamondPearl/LegendsAwakened',
                element: <TemplateSet setInfo={allEnglishSets.LegendsAwakened} />,
                state: 'englishDiamondPearlSeries.englishLegendsAwakened',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Legends Awakened',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.LegendsAwakenedIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/DiamondPearl/Stormfront',
                element: <TemplateSet setInfo={allEnglishSets.Stormfront} />,
                state: 'englishDiamondPearlSeries.englishStormfront',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Stormfront',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.StormfrontIcon}
                            alt=""
                        />
                    ),
                },
            },
        ],
    },
    {
        path: '/eng/EXSeries',
        element: <TemplateLayout />,
        state: 'englishEXSeries',
        tcgState: 'English',
        beta: false,
        sidebarProps: {
            displayText: 'EX Series',
        },
        child: [
            {
                index: true,
                element: <TemplateIndex />,
                state: 'englishEXSeries.index',
                tcgState: 'English',
                beta: false,
            },
            {
                path: '/eng/EXSeries/EXRubySapphire',
                element: <TemplateSet setInfo={allEnglishSets.EXRubySapphire} />,
                state: 'englishEXSeries.EXRuby&Sapphire',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'EX Ruby & Sapphire',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EXRubySapphireIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/EXSeries/EXSandstorm',
                element: <TemplateSet setInfo={allEnglishSets.EXSandstorm} />,
                state: 'englishEXSeries.EXSandstorm',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'EX Sandstorm',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EXSandstormIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/EXSeries/EXDragon',
                element: <TemplateSet setInfo={allEnglishSets.EXDragon} />,
                state: 'englishEXSeries.EXDragon',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'EX Dragon',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EXDragonIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/EXSeries/EXTeamMagmavsTeamAqua',
                element: <TemplateSet setInfo={allEnglishSets.EXTeamMagmavsTeamAqua} />,
                state: 'englishEXSeries.EXTeamMagmavsTeamAqua',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'EX Team Magma vs Team Aqua',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EXTeamMagmavsTeamAquaIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/EXSeries/EXHiddenLegends',
                element: <TemplateSet setInfo={allEnglishSets.EXHiddenLegends} />,
                state: 'englishEXSeries.EXHiddenLegends',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'EX Hidden Legends',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EXHiddenLegendsIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/EXSeries/EXFireRedLeafGreen',
                element: <TemplateSet setInfo={allEnglishSets.EXFireRedLeafGreen} />,
                state: 'englishEXSeries.EXFireRedLeafGreen',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'EX FireRed & LeafGreen',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EXRireRedLeafGreenIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/EXSeries/EXTeamRocketReturns',
                element: <TemplateSet setInfo={allEnglishSets.EXTeamRocketReturns} />,
                state: 'englishEXSeries.EXTeamRocketReturns',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'EX Team Rocket Returns',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EXTeamRocketReturnsIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/EXSeries/EXDeoxys',
                element: <TemplateSet setInfo={allEnglishSets.EXDeoxys} />,
                state: 'englishEXSeries.EXDeoxys',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'EX Deoxys',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EXDeoxysIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/EXSeries/EXEmerald',
                element: <TemplateSet setInfo={allEnglishSets.EXEmerald} />,
                state: 'englishEXSeries.EXEmerald',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'EX Emerald',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EXEmeraldIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/EXSeries/EXUnseenForces',
                element: <TemplateSet setInfo={allEnglishSets.EXUnseenForces} />,
                state: 'englishEXSeries.EXUnseenForces',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'EX Unseen Forces',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EXUnseenForcesIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/EXSeries/EXDeltaSpecies',
                element: <TemplateSet setInfo={allEnglishSets.EXDeltaSpecies} />,
                state: 'englishEXSeries.EXDeltaSpecies',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'EX Delta Species',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EXDeltaSpeciesIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/EXSeries/EXLegendMaker',
                element: <TemplateSet setInfo={allEnglishSets.EXLegendMaker} />,
                state: 'englishEXSeries.EXLegendMaker',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'EX Legend Maker',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EXLegendMakerIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/EXSeries/EXHolonPhantoms',
                element: <TemplateSet setInfo={allEnglishSets.EXHolonPhantoms} />,
                state: 'englishEXSeries.EXHolonPhantoms',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'EX Holon Phantoms',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EXHolonPhantomsIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/EXSeries/EXCrystalGuardians',
                element: <TemplateSet setInfo={allEnglishSets.EXCrystalGuardians} />,
                state: 'englishEXSeries.EXCrystalGuardians',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'EX Crystal Guardians',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EXCrystalGuardiansIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/EXSeries/EXDragonFrontiers',
                element: <TemplateSet setInfo={allEnglishSets.EXDragonFrontiers} />,
                state: 'englishEXSeries.EXDragonFrontiers',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'EX Dragon Frontiers',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EXDragonFrontiersIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/EXSeries/EXPowerKeepers',
                element: <TemplateSet setInfo={allEnglishSets.EXPowerKeepers} />,
                state: 'englishEXSeries.EXPowerKeepers',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'EX Power Keepers',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.EXPowerKeepersIcon}
                            alt=""
                        />
                    ),
                },
            },
        ],
    },
    {
        path: '/eng/LegendaryCollectionSeries',
        element: <TemplateLayout />,
        state: 'englishLegendaryCollectionSeries',
        tcgState: 'English',
        beta: false,
        sidebarProps: {
            displayText: 'Legendary Collection Series',
        },
        child: [
            {
                index: true,
                element: <TemplateIndex />,
                state: 'englishLegendaryCollectionSeries.index',
                tcgState: 'English',
                beta: false,
            },
            {
                path: '/eng/LegendaryCollectionSeries/LegendaryCollection',
                element: <TemplateSet setInfo={allEnglishSets.LegendaryCollection} />,
                state: 'englishLegendaryCollectionSeries.legendaryCollection',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Legendary Collection',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.legendaryCollectionIcon}
                            alt=""
                        />
                    ),
                },
            },
        ],
    },
    {
        path: '/eng/eCardSeries',
        element: <TemplateLayout />,
        state: 'englisheCardSeries',
        tcgState: 'English',
        beta: false,
        sidebarProps: {
            displayText: 'eCard Series',
        },
        child: [
            {
                index: true,
                element: <TemplateIndex />,
                state: 'englisheCardSeries.index',
                tcgState: 'English',
                beta: false,
            },
            {
                path: '/eng/eCardSeries/Expedition',
                element: <TemplateSet setInfo={allEnglishSets.Expedition} />,
                state: 'englisheCardSeries.expedition',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Expedition',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.expeditionIcon2}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/eCardSeries/Aquapolis',
                element: <TemplateSet setInfo={allEnglishSets.Aquapolis} />,
                state: 'englisheCardSeries.aquapolis',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Aquapolis',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.aquapolisIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/eCardSeries/Skyridge',
                element: <TemplateSet setInfo={allEnglishSets.Skyridge} />,
                state: 'englisheCardSeries.skyridge',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Skyridge',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.skyridgeIcon2}
                            alt=""
                        />
                    ),
                },
            },
        ],
    },
    {
        path: '/eng/NeoSeries',
        element: <TemplateLayout />,
        state: 'englishNeoSeries',
        tcgState: 'English',
        beta: false,
        sidebarProps: {
            displayText: 'Neo Series',
        },
        child: [
            {
                index: true,
                element: <TemplateIndex />,
                state: 'englishNeoSeries.index',
                tcgState: 'English',
                beta: false,
            },
            {
                path: '/eng/NeoSeries/NeoGenesis',
                element: <TemplateSet setInfo={allEnglishSets.NeoGenesis} />,
                state: 'englishNeoSeries.neoGenesis',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'NeoGenesis',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.neoGenesisIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/NeoSeries/NeoDiscovery',
                element: <TemplateSet setInfo={allEnglishSets.NeoDiscovery} />,
                state: 'englishNeoSeries.neoDiscovery',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Neo Discovery',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.neoDiscoveryIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/NeoSeries/NeoRevelation',
                element: <TemplateSet setInfo={allEnglishSets.NeoRevelation} />,
                state: 'englishNeoSeries.neoRevelation',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Neo Revelation',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.neoRevelationIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/NeoSeries/NeoDestiny',
                element: <TemplateSet setInfo={allEnglishSets.NeoDestiny} />,
                state: 'englishNeoSeries.neoDestiny',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Neo Destiny',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.neoDestinyIcon}
                            alt=""
                        />
                    ),
                },
            },
        ],
    },
    {
        path: '/eng/GymSeries',
        element: <TemplateLayout />,
        state: 'englishGymSeries',
        tcgState: 'English',
        beta: false,
        sidebarProps: {
            displayText: 'Gym Series',
        },
        child: [
            {
                index: true,
                element: <TemplateIndex />,
                state: 'englishGymSeries.index',
                tcgState: 'English',
                beta: false,
            },
            {
                path: '/eng/GymSeries/GymHeroes',
                element: <TemplateSet setInfo={allEnglishSets.GymHeroes} />,
                state: 'englishGymSeries.gymheroes',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Gym Heroes',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.gymHeroesIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/GymSeries/GymChallenge',
                element: <TemplateSet setInfo={allEnglishSets.GymChallenge} />,
                state: 'englishGymSeries.gymChallenge',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Gym Challenge',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.gymChallengeIcon}
                            alt=""
                        />
                    ),
                },
            },
        ],
    },
    {
        path: '/eng/BaseSeries',
        element: <TemplateLayout />,
        state: 'englishBaseSeries',
        tcgState: 'English',
        beta: false,
        sidebarProps: {
            displayText: 'Base Series',
        },
        child: [
            {
                index: true,
                element: <TemplateIndex />,
                state: 'englishBaseSeries.index',
                tcgState: 'English',
                beta: false,
            },
            {
                path: '/eng/BaseSeries/BaseSet',
                element: <TemplateSet setInfo={allEnglishSets.BaseSet} />,
                state: 'englishBaseSeries.baseSet',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Base Set',
                },
            },
            {
                path: '/eng/BaseSeries/Jungle',
                element: <TemplateSet setInfo={allEnglishSets.Jungle} />,
                state: 'englishBaseSeries.jungle',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Jungle',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.jungleIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/BaseSeries/Fossil',
                element: <TemplateSet setInfo={allEnglishSets.Fossil} />,
                state: 'englishBaseSeries.fossil',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Fossil',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.fossilIcon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/BaseSeries/BaseSet2',
                element: <TemplateSet setInfo={allEnglishSets.baseSet2} />,
                state: 'englishBaseSeries.baseSet2',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Base Set 2',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.baseSet2Icon}
                            alt=""
                        />
                    ),
                },
            },
            {
                path: '/eng/BaseSeries/TeamRocket',
                element: <TemplateSet setInfo={allEnglishSets.TeamRocket} />,
                state: 'englishBaseSeries.teamRocket',
                tcgState: 'English',
                beta: false,
                sidebarProps: {
                    displayText: 'Team Rocket',
                    icon: (
                        <img
                            style={{ width: 'auto', height: 'auto', maxWidth: '30px' }}
                            src={assets.english.images.teamRocketIcon}
                            alt=""
                        />
                    ),
                },
            },
        ],
    },
];

export default englishAppRoutes;
