import React from 'react';
import { Paper, Stack, Divider, Typography, Box } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface SetInfoHeaderProps {
    setInfo: {
        logo: string;
        setName: string;
        icon?: string;
        releaseDate: string;
        setSeries: string;
    };
    dynamicLink: string;
    assets: {
        site: {
            images: {
                tcgplayerIcon1: string;
            };
        };
    };
}

const DesktopSetInfoHeader: React.FC<SetInfoHeaderProps> = ({ setInfo, dynamicLink, assets }) => (
    <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
        <Paper elevation={4} sx={{ p: 2, width: '100%' }}>
            <Stack direction="row" spacing={2} alignItems="stretch" sx={{ height: '100%' }}>
                <LazyLoadImage
                    className="image"
                    src={setInfo.logo}
                    alt={`${setInfo.setName} Logo`}
                    effect="blur"
                    style={{
                        width: 'auto',
                        height: 'auto',
                        maxWidth: '175px',
                        alignSelf: 'center',
                    }}
                />
                <Divider orientation="vertical" flexItem />
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    sx={{ flexGrow: 1 }}
                >
                    <Box sx={{ display: 'flex', flexGrow: 1, flexWrap: 'wrap', gap: 2 }}>
                        <Box sx={{ display: 'flex', flexGrow: 1, minWidth: '200px' }}>
                            <InfoColumn label="Set Name" value={setInfo.setName} />
                            <InfoColumn label="Set Series" value={setInfo.setSeries} />
                        </Box>
                        <Box sx={{ display: 'flex', flexGrow: 1, minWidth: '200px' }}>
                            {setInfo.icon && (
                                <InfoColumn
                                    label="Set Icon"
                                    value={
                                        <img
                                            src={setInfo.icon}
                                            alt={`${setInfo.setName} Icon`}
                                            style={{
                                                width: 'auto',
                                                height: 'auto',
                                                maxWidth: '30px',
                                            }}
                                        />
                                    }
                                />
                            )}
                            <InfoColumn label="Release Date" value={setInfo.releaseDate} />
                        </Box>
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Stack alignItems="center" justifyContent="center" sx={{ minWidth: '120px' }}>
                        <Typography variant="subtitle2" fontWeight="bold" align="center">
                            Shop At
                        </Typography>
                        <a href={dynamicLink} target="_blank" rel="noreferrer" id="1808389">
                            <img
                                src={assets.site.images.tcgplayerIcon1}
                                alt=""
                                width="100"
                                height="39"
                            />
                        </a>
                        <img
                            height="0"
                            width="0"
                            src="https://imp.pxf.io/i/5054575/1808389/21018"
                            alt=""
                            style={{ position: 'absolute', visibility: 'hidden' }}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    </Stack>
);

const InfoColumn: React.FC<{ label: string; value: React.ReactNode }> = ({ label, value }) => (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Typography variant="subtitle2" fontWeight="bold">
            {label}
        </Typography>
        <Typography variant="body2">{value}</Typography>
    </Box>
);

export default DesktopSetInfoHeader;
