import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';

export const useFriendFunctions = (userName: string | undefined) => {
    const [friendStatus, setFriendStatus] = useState('not_friends');
    const [friendCount, setFriendCount] = useState(0);
    const [isRequester, setIsRequester] = useState(false);
    const [friendLoading, setFriendLoading] = useState(false);

    const { isAuthenticated, authParams } = useAuth();
    const currentUserName = localStorage.getItem('userName') || '';

    const checkFriendStatus = useCallback(() => {
        if (!userName || !currentUserName || !authParams) return;

        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();

        const authParamsObj = Object.fromEntries(new URLSearchParams(queryParams));

        const requestBody = {
            ...authParamsObj,
            targetUserName: userName || '',
            currentUserName,
        };

        const queryString = new URLSearchParams(requestBody).toString();

        fetch(`/api/checkFriendStatus?${queryString}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    setFriendStatus(result.status);
                    setIsRequester(result.isRequester);
                } else {
                    // console.error('Failed to fetch friend status:', result.message);
                }
            })
            .catch((error) => {
                // console.error('Error checking friend status:', error);
            });
    }, [userName, currentUserName, authParams]);

    const getFriendCount = useCallback(() => {
        const queryString = new URLSearchParams({ userName: userName || '' }).toString();
        fetch(`/api/getFriendCount?${queryString}`)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    setFriendCount(result.friendCount || 0);
                } else {
                    toast.error('Failed to fetch friend count');
                }
            })
            .catch((error) => {
                toast.error('An error occurred while fetching friend count');
                // console.error('Error fetching friend count:', error);
            });
    }, [userName]);

    useEffect(() => {
        if (!userName || !isAuthenticated || !authParams) return;
        checkFriendStatus();
        getFriendCount();
    }, [userName, isAuthenticated, currentUserName, authParams, checkFriendStatus, getFriendCount]);

    const handleAPIResult = (result: { success: boolean; failed: boolean; message: string }) => {
        if (result.success) {
            toast.success(result.message, {
                autoClose: 1000,
            });
        } else if (result.failed) {
            toast.error(result.message, {
                autoClose: 1000,
            });
        }
    };

    const handleFriendAction = () => {
        if (!authParams) return;
        setFriendLoading(true);

        const endpoint = friendStatus === 'friends' ? '/api/removeFriend' : '/api/requestFriend';

        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams(stringAuthParams).toString();

        const authParamsObj = Object.fromEntries(new URLSearchParams(queryParams));

        const requestBody = {
            ...authParamsObj,
            targetUserName: userName || '',
            currentUserName,
        };

        fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        })
            .then((response) => response.json())
            .then((result) => {
                handleAPIResult(result);

                if (result.success) {
                    if (friendStatus === 'friends') {
                        setFriendStatus('not_friends');
                        setIsRequester(false);
                    } else {
                        setFriendStatus('pending');
                        setIsRequester(true);
                    }
                    getFriendCount();
                } else {
                    toast.error(
                        `Failed to ${friendStatus === 'friends' ? 'remove' : 'add'} friend`
                    );
                }
            })
            .catch((error) => {
                toast.error(
                    `An error occurred while ${friendStatus === 'friends' ? 'removing' : 'adding'} the friend`
                );
                // console.error(
                //     `Error ${friendStatus === 'friends' ? 'removing' : 'adding'} friend:`,
                //     error
                // );
            })
            .finally(() => {
                setFriendLoading(false);
            });
    };

    const handleFriendResponse = (action: 'accept' | 'reject') => {
        if (!authParams) return;
        setFriendLoading(true);

        fetch('/api/requestResponse', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                currentUserName,
                targetUserName: userName,
                action,
                ...authParams,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                handleAPIResult(result);

                if (result.success) {
                    setFriendStatus(action === 'accept' ? 'friends' : 'not_friends');
                    setIsRequester(false);
                    getFriendCount();
                } else {
                    toast.error(`Failed to ${action} friend request`);
                }
            })
            .catch((error) => {
                toast.error(`An error occurred while ${action}ing the friend request`);
                // console.error(`Error ${action}ing friend request:`, error);
            })
            .finally(() => {
                setFriendLoading(false);
            });
    };

    return {
        friendStatus,
        friendCount,
        isRequester,
        friendLoading,
        handleFriendAction,
        handleFriendResponse,
    };
};
