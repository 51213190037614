import { AppBar, Toolbar, Typography } from '@mui/material';
import colorConfigs from '../../configs/colorConfigs';
import sizeConfigs from '../../configs/sizeConfigs';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography
                    className="bottombar-progressbar-label"
                    variant="body2"
                    color="text.secondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

const Bottombar = () => {
    const tcgState = useSelector((state: RootState) => state.TCGState);
    const userName = useSelector((state: RootState) => state.userName);
    const userSetCollectedCards = useSelector((state: RootState) => state.userSetCollectedCards);
    const setNumOfCards = useSelector((state: RootState) => state.setNumOfCards);
    const setNumOfReverseCards = useSelector((state: RootState) => state.setNumOfReverseCards);
    const seriesName = useSelector((state: RootState) => state.seriesName);
    const setName = useSelector((state: RootState) => state.setName);
    const authenticated = useSelector((state: RootState) => state.authenticated);
    const page = useSelector((state: RootState) => state.page);
    const authenticatedStorage = localStorage.getItem('authenticated');

    const normalise = (value: number) => ((value - 0) * 100) / (setNumOfCards - 0);

    useEffect(() => {}, [
        authenticated,
        authenticatedStorage,
        tcgState,
        userName,
        userSetCollectedCards,
        setNumOfCards,
        setNumOfReverseCards,
        seriesName,
        setName,
        page,
    ]);

    let message = `${tcgState} - ${seriesName} - ${setName} - ${userSetCollectedCards}/${setNumOfCards} Cards`;

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    top: 'auto',
                    bottom: 0,
                    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
                    ml: sizeConfigs.sidebar.width,
                    boxShadow: 'unset',
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color,
                }}
            >
                <Toolbar>
                    <Typography className="bottombar-progressbar-title">{message}</Typography>
                </Toolbar>
                <LinearProgressWithLabel
                    className="bottombar-progressbar"
                    value={normalise(userSetCollectedCards)}
                />
            </AppBar>
        </>
    );
};

export default Bottombar;
