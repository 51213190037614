import React from 'react';
import { Paper, Stack, Grid, Typography } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface MobileSetInfoHeaderProps {
    setInfo: {
        logo: string;
        setName: string;
        icon?: string;
        releaseDate: string;
        setSeries: string;
    };
    dynamicLink: string;
    assets: {
        site: {
            images: {
                tcgplayerIcon1: string;
            };
        };
    };
}

const styles = {
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    label: {
        fontWeight: 'bold',
        marginBottom: '4px',
    },
    value: {
        fontSize: '14px',
    },
};

const MobileSetInfoHeader: React.FC<MobileSetInfoHeaderProps> = ({
    setInfo,
    dynamicLink,
    assets,
}) => (
    <>
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                maxWidth: '600px',
                margin: '0 auto',
            }}
        >
            <LazyLoadImage
                className="image"
                src={setInfo.logo}
                alt={`${setInfo.setName} Logo`}
                effect="blur"
                style={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: '100%',
                    objectFit: 'contain',
                }}
            />
        </div>
        <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
            <Paper elevation={4} style={{ padding: '16px', width: '100%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" style={styles.label}>
                            Set Name
                        </Typography>
                        <Typography variant="body2" style={styles.value}>
                            {setInfo.setName}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" style={styles.label}>
                            Release Date
                        </Typography>
                        <Typography variant="body2" style={styles.value}>
                            {setInfo.releaseDate}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" style={styles.label}>
                            Set Icon
                        </Typography>
                        {setInfo.icon && (
                            <img
                                src={setInfo.icon}
                                alt={`${setInfo.setName} Icon`}
                                style={{
                                    width: 'auto',
                                    height: 'auto',
                                    maxWidth: '30px',
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" style={styles.label}>
                            Shop At
                        </Typography>
                        <a href={dynamicLink} target="_blank" rel="noreferrer" id="1808389">
                            <img
                                src={assets.site.images.tcgplayerIcon1}
                                alt=""
                                width="100"
                                height="39"
                            />
                        </a>
                        <img
                            height="0"
                            width="0"
                            src="https://imp.pxf.io/i/5054575/1808389/21018"
                            alt=""
                            style={{ visibility: 'hidden' }}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Stack>
    </>
);

export default MobileSetInfoHeader;
