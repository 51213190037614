import React, { useState, useEffect, useRef } from 'react';
import '../../../css/popup.css';
import assets from '../../../assets';
import Box from '@mui/joy/Box';
import { styled } from '@mui/material/styles';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/joy/Divider';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

type Props = {
    cardInfo: {
        id: number;
        cardsetindex: number;
        editionId?: number;
        imageLocation2x: string;
        imagelocation: string;
        isCardInCollection: boolean;
        isErrorCard: number;
        isPartOfSubSet: number;
        isPromoCard: number;
        isReverseHolo: number;
        languageId: number;
        market_price: number;
        number: string;
        pokemonsetId: number;
        rarityId?: number;
        seriesId?: number;
        showPriceData?: boolean;
        name: string;
        seriesName: string;
        setName: string;
        product_id: number;
        tcgPlayerProductLink: string;
        setSeries: string;
        icon?: string;
        type: string;
        setPrintRuns?: {
            firstEditionSet?: {
                buttonDisplay: string;
                description: string;
            };
            shadowlessSet?: {
                buttonDisplay: string;
                description: string;
            };
            unlimitedEditionSet?: {
                buttonDisplay: string;
                description: string;
            };
            fourthPrintSet?: {
                buttonDisplay: string;
                description: string;
            };
        };
    };
    onClose: () => void;
};

type UserCardProps = {
    cardInfo: Props['cardInfo'];
};

const BorderlessCard = styled(Card)({
    border: 'none',
    boxShadow: 'none',
});

const UserCard = ({ cardInfo }: UserCardProps) => {
    const [data, setData] = useState({
        edition: null,
        rarity: null,
        hp: null,
        weakness: null,
        resistance: null,
        retreatCost: null,
        type: null,
        typeIcon: null,
        stage: null,
        attacks: [
            {
                attackOriginalString: null,
                attackName: null,
                attackDescription: null,
                attackDamage: null,
                attackEnergyCost: null,
            },
            {
                attackOriginalString: null,
                attackName: null,
                attackDescription: null,
                attackDamage: null,
                attackEnergyCost: null,
            },
        ],
    });

    const originalLink = 'https://tcgplayer.pxf.io/c/5054575/1808389/21018';
    const tcgPlayerProductId = cardInfo.product_id;
    let modifiedLink = '';

    if (cardInfo.type === 'WOTC' && cardInfo?.setPrintRuns?.firstEditionSet) {
        if (cardInfo.editionId === 1) {
            modifiedLink = `${originalLink}?u=https://www.tcgplayer.com/product/${tcgPlayerProductId}?Printing=1st+Edition+Holofoil|1st+Edition`;
        } else if (cardInfo.editionId === 3) {
            modifiedLink = `${originalLink}?u=https://www.tcgplayer.com/product/${tcgPlayerProductId}?Printing=Unlimited+Holofoil|Unlimited`;
        }
    } else {
        if (cardInfo.isReverseHolo) {
            modifiedLink = `${originalLink}?u=https://www.tcgplayer.com/product/${tcgPlayerProductId}?Printing=Reverse+Holofoil`;
        } else if (cardInfo.isPartOfSubSet) {
            modifiedLink = `${originalLink}?u=https://www.tcgplayer.com/product/${tcgPlayerProductId}?Printing=Holofoil`;
        } else {
            modifiedLink = `${originalLink}?u=https://www.tcgplayer.com/product/${tcgPlayerProductId}?Printing=Normal|Holofoil`;
        }
    }

    useEffect(() => {
        const fetchCardDetails = async () => {
            if (cardInfo.id) {
                try {
                    const response = await fetch(`/api/getCardDetails/${cardInfo.id}`);
                    const cardDetails = await response.json();

                    setData({
                        edition: cardDetails.editionName,
                        rarity: cardDetails.cardRarityName,
                        hp: cardDetails.cardHP,
                        weakness: cardDetails.cardWeakness,
                        resistance: cardDetails.cardResistance,
                        retreatCost: cardDetails.cardRetreatCost,
                        type: cardDetails.cardType,
                        typeIcon: cardDetails.cardIcon,
                        stage: cardDetails.cardStageName,
                        attacks: (cardDetails.attacks || []).slice(0, 2),
                    });
                } catch (error) {
                    // console.error('Error fetching card details:', error);
                }
            }
        };

        fetchCardDetails();
    }, [cardInfo.id]);

    return (
        <Box
            sx={{
                width: '100%',
                position: 'relative',
                overflow: { xs: 'auto', sm: 'initial' },
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    display: 'block',
                    bgcolor: 'warning.300',
                    left: '500px',
                    top: '-24px',
                    bottom: '-24px',
                    '&::before': {
                        top: '4px',
                        display: 'block',
                        position: 'absolute',
                        right: '0.5rem',
                        color: 'text.tertiary',
                        fontSize: 'sm',
                        fontWeight: 'lg',
                    },
                    '&::after': {
                        top: '4px',
                        display: 'block',
                        position: 'absolute',
                        left: '0.5rem',
                        color: 'text.tertiary',
                        fontSize: 'sm',
                        fontWeight: 'lg',
                    },
                }}
            />

            <BorderlessCard
                orientation="horizontal"
                sx={{
                    width: '100%',
                    flexWrap: 'wrap',
                    [`& > *`]: {
                        '--stack-point': '790px',
                    },
                    resize: 'horizontal',
                }}
            >
                <LazyLoadImage
                    className="cardTemplate-card-image"
                    src={
                        cardInfo && cardInfo.imageLocation2x
                            ? cardInfo.imageLocation2x
                            : cardInfo.imagelocation || assets.site.images.backupImage
                    }
                    alt={cardInfo && cardInfo.name}
                    effect="blur"
                    wrapperClassName="cardTemplate-image-wrapper"
                    style={{
                        width: '100%',
                        height: 'auto',
                        maxWidth: '400px',
                        objectFit: 'contain',
                        background: 'transparent',
                        boxShadow: 'none',
                        border: 'none',
                    }}
                />
                <CardContent sx={{ border: 'none' }}>
                    <Typography level="h1" fontWeight="lg">
                        {cardInfo.name}
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            className="cardTemplate-card-image"
                            loading="lazy"
                            src={cardInfo.icon}
                            alt={cardInfo && cardInfo.name}
                            style={{
                                width: 'auto',
                                height: 'auto',
                                maxWidth: '30px',
                                border: '1px solid black',
                                marginRight: '8px',
                            }}
                        />
                        <Typography level="h4" fontWeight="lg" textColor="text.tertiary">
                            {cardInfo.setName}
                        </Typography>
                    </div>
                    <Divider />
                    <Sheet
                        sx={{
                            bgcolor: 'background.level2',
                            borderRadius: 'sm',
                            p: 1.5,
                            my: 1.5,
                            display: 'flex',
                            gap: 2,
                            '& > div': { flex: 1 },
                        }}
                    >
                        <div>
                            <Typography level="body-xs" fontWeight="lg">
                                Series Name
                            </Typography>
                            <Typography fontWeight="lg">{cardInfo.setSeries}</Typography>
                        </div>
                        <div>
                            <Typography level="body-xs" fontWeight="lg">
                                Set Name
                            </Typography>
                            <Typography fontWeight="lg">{cardInfo.setName}</Typography>
                        </div>
                        <div>
                            <Typography level="body-xs" fontWeight="lg">
                                Card Number
                            </Typography>
                            <Typography fontWeight="lg">{cardInfo.number}</Typography>
                        </div>
                    </Sheet>
                    <Sheet
                        sx={{
                            bgcolor: 'background.level2',
                            borderRadius: 'sm',
                            p: 1.5,
                            my: 1.5,
                            display: 'flex',
                            gap: 2,
                            '& > div': { flex: 1 },
                        }}
                    >
                        <div>
                            <Typography level="body-xs" fontWeight="lg">
                                Purchase Card
                            </Typography>
                            <Typography fontWeight="lg">TCGPlayer</Typography>
                        </div>
                        <div className="vertical-align-container">
                            <a href={modifiedLink} target="_blank" rel="noreferrer" id="1808389">
                                <img
                                    src={assets.site.images.tcgplayerIcon1}
                                    alt=""
                                    width="100"
                                    height="39"
                                />
                            </a>
                        </div>
                        <div>
                            <Typography level="body-xs" fontWeight="lg">
                                Market Price
                            </Typography>
                            {cardInfo && typeof cardInfo.market_price !== 'undefined'
                                ? `$${cardInfo.market_price.toFixed(2)}`
                                : 'Loading...'}
                        </div>
                    </Sheet>

                    <Divider>Card Infomation</Divider>

                    <Sheet
                        sx={{
                            bgcolor: 'background.level2',
                            borderRadius: 'sm',
                            p: 1.5,
                            my: 1.5,
                            display: 'flex',
                            gap: 2,
                            '& > div': { flex: 1 },
                        }}
                    >
                        {cardInfo.type === 'WOTC' && (
                            <div>
                                <Typography level="body-xs" fontWeight="lg">
                                    Edition
                                </Typography>
                                <Typography fontWeight="lg">
                                    {data.edition ? data.edition : 'N/A'}
                                </Typography>
                            </div>
                        )}
                        <div>
                            <Typography level="body-xs" fontWeight="lg">
                                Rarity
                            </Typography>
                            <Typography fontWeight="lg">
                                {data.rarity ? data.rarity : 'N/A'}
                            </Typography>
                        </div>

                        <div>
                            <Typography level="body-xs" fontWeight="lg">
                                Type
                            </Typography>
                            <Typography fontWeight="lg">{data.type ? data.type : 'N/A'}</Typography>
                        </div>
                        <div>
                            <Typography level="body-xs" fontWeight="lg">
                                Stage
                            </Typography>
                            <Typography fontWeight="lg">
                                {data.stage ? data.stage : 'N/A'}
                            </Typography>
                        </div>
                    </Sheet>
                    <Sheet
                        sx={{
                            bgcolor: 'background.level2',
                            borderRadius: 'sm',
                            p: 1.5,
                            my: 1.5,
                            display: 'flex',
                            gap: 2,
                            '& > div': { flex: 1 },
                        }}
                    >
                        <div>
                            <Typography level="body-xs" fontWeight="lg">
                                HP
                            </Typography>
                            <Typography fontWeight="lg">{data.hp ? data.hp : 'N/A'}</Typography>
                        </div>
                        <div>
                            <Typography level="body-xs" fontWeight="lg">
                                Weakness
                            </Typography>
                            <Typography fontWeight="lg">
                                {data.weakness ? data.weakness : 'N/A'}
                            </Typography>
                        </div>
                        <div>
                            <Typography level="body-xs" fontWeight="lg">
                                Resistance
                            </Typography>
                            <Typography fontWeight="lg">
                                {data.resistance ? data.resistance : 'N/A'}
                            </Typography>
                        </div>
                        <div>
                            <Typography level="body-xs" fontWeight="lg">
                                Retreat Cost
                            </Typography>
                            <Typography fontWeight="lg">
                                {data.retreatCost ? data.retreatCost : 'N/A'}
                            </Typography>
                        </div>
                    </Sheet>
                    <Sheet
                        sx={{
                            bgcolor: 'background.level2',
                            borderRadius: 'sm',
                            p: 1.5,
                            my: 1.5,
                            display: 'flex',
                            gap: 2,
                            flexDirection: 'column',
                        }}
                    >
                        {data.attacks[0] &&
                            (data.attacks[0]?.attackName &&
                            data.attacks[0]?.attackDescription &&
                            data.attacks[0]?.attackDamage &&
                            data.attacks[0]?.attackEnergyCost ? (
                                <div>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Attack 1
                                    </Typography>
                                    <Typography fontWeight="lg">
                                        EnergyCost: {data.attacks[0]?.attackEnergyCost || 'N/A'}
                                    </Typography>
                                    <Typography fontWeight="lg">
                                        Name: {data.attacks[0]?.attackName || 'N/A'}
                                    </Typography>
                                    <Typography fontWeight="lg">
                                        Damge: {data.attacks[0]?.attackDamage || 'N/A'}
                                    </Typography>
                                    <Typography fontWeight="lg">
                                        Description: {data.attacks[0]?.attackDescription || 'N/A'}
                                    </Typography>
                                    {/* Include other attack details as needed */}
                                </div>
                            ) : (
                                <div>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Attack 1
                                    </Typography>
                                    <Typography fontWeight="lg">
                                        {data.attacks[0]?.attackOriginalString || 'N/A'}
                                    </Typography>
                                </div>
                            ))}
                        {data.attacks[1] &&
                            (data.attacks[1]?.attackName &&
                            data.attacks[1]?.attackDescription &&
                            data.attacks[1]?.attackDamage &&
                            data.attacks[1]?.attackEnergyCost ? (
                                <div>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Attack 2
                                    </Typography>
                                    <Typography fontWeight="lg">
                                        EnergyCost: {data.attacks[1]?.attackEnergyCost || 'N/A'}
                                    </Typography>
                                    <Typography fontWeight="lg">
                                        Name: {data.attacks[1]?.attackName || 'N/A'}
                                    </Typography>
                                    <Typography fontWeight="lg">
                                        Damage: {data.attacks[1]?.attackDamage || 'N/A'}
                                    </Typography>
                                    <Typography fontWeight="lg">
                                        Description: {data.attacks[1]?.attackDescription || 'N/A'}
                                    </Typography>
                                    {/* Include other attack details as needed */}
                                </div>
                            ) : (
                                <div>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Attack 2
                                    </Typography>
                                    <Typography fontWeight="lg">
                                        {data.attacks[1]?.attackOriginalString || 'N/A'}
                                    </Typography>
                                </div>
                            ))}
                    </Sheet>
                </CardContent>
            </BorderlessCard>
        </Box>
    );
};

const CardTemplate: React.FC<Props> = ({ cardInfo, onClose }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <Box className="popup-overlay">
            <Box ref={containerRef} className="popup-container">
                <Box className="popup-header">
                    <h2 className="popup-title">{cardInfo.name}</h2>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className="popup-content">{cardInfo && <UserCard cardInfo={cardInfo} />}</Box>
            </Box>
        </Box>
    );
};

export default CardTemplate;
