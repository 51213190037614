import { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import PageWrapper from '../components/layout/PageWrapper';
import appRoutes from './appRoutes';
import { RouteType } from './config';
import { useUserFunctions } from '../hooks/useUserFunctions';
import { useAuth } from '../context/AuthContext';
import { getApplicationRoutes } from '../api/adminApi';
import { getComponent } from '../utils/componentRegistry';
import { 
    AdminPanelSettings, 
    Dashboard, 
    Category, 
    Star, 
    GroupWork, 
    Image, 
    CatchingPokemon 
} from '@mui/icons-material';

const iconMap: Record<string, React.ReactElement> = {
    'AdminPanelSettings': <AdminPanelSettings />,
    'Dashboard': <Dashboard />,
    'Category': <Category />,
    'Star': <Star />,
    'GroupWork': <GroupWork />,
    'Image': <Image />,
    'CatchingPokemon': <CatchingPokemon />
};

// Helper function to convert API route data to RouteType
const processApiRoute = (route: any): RouteType => {
    const Component = route.component_name ? getComponent(route.component_name) : null;
    
    // Process sidebar props
    let sidebarProps = undefined;
    if (route.sidebarProps || route.processed_sidebar_props) {
        try {
            // Try to parse if it's a string, otherwise use as is
            const parsedProps = typeof route.processed_sidebar_props === 'string' 
                ? JSON.parse(route.processed_sidebar_props)
                : route.processed_sidebar_props || route.sidebarProps;

            sidebarProps = {
                ...parsedProps,
                icon: parsedProps.icon ? iconMap[parsedProps.icon] || null : undefined
            };
        } catch (error) {
            // console.error('Error processing sidebar props:', error);
        }
    }
    
    // console.log(`Processing route: ${route.path}`, {
    //     componentName: route.component_name,
    //     componentFound: !!Component,
    //     sidebarProps,
    //     route
    // });
    
    const processedRoute: RouteType = {
        path: route.is_index ? undefined : route.path,
        element: Component ? <Component /> : 
            <div style={{padding: '20px', color: 'red'}}>
                Component "{route.component_name}" not found
            </div>,
        state: route.state,
        tcgState: route.tcg_state || 'All',
        beta: Boolean(route.is_beta),
        index: Boolean(route.is_index),
        sidebarProps
    };

    // Only add child property if there are children
    if (route.child && route.child.length > 0) {
        processedRoute.child = route.child.map(processApiRoute);
    }

    return processedRoute;
};

const generateRoute = (routes: RouteType[]): React.ReactElement[] => {
    return routes.map((route, index) => {
        const routeKey = route.path || `index-${index}`;
        
        if (route.index) {
            return (
                <Route
                    index
                    element={<PageWrapper state={route.state}>{route.element}</PageWrapper>}
                    key={routeKey}
                />
            );
        }
        
        return (
            <Route
                path={route.path}
                element={
                    <PageWrapper state={route.child ? undefined : route.state}>
                        {route.element}
                    </PageWrapper>
                }
                key={routeKey}
            >
                {route.child && generateRoute(route.child)}
            </Route>
        );
    });
};

export const useRoutes = () => {
    const [routes, setRoutes] = useState<RouteType[]>([...appRoutes]);
    const { isAdmin } = useUserFunctions();
    const { authParams } = useAuth();

    useEffect(() => {
        const fetchRoutes = async () => {
            try {
                // Pass authParams only if they exist
                const result = await getApplicationRoutes(authParams || undefined);
                // console.log('API Response:', result);

                if (result.success && result.routes) {
                    // Process API routes
                    const apiRoutes = result.routes.map(processApiRoute);
                    // console.log('Processed API routes:', apiRoutes);
                    
                    // Put API routes first, then app routes
                    const combinedRoutes = [...apiRoutes, ...appRoutes];
                    // console.log('Combined routes:', combinedRoutes);
                    setRoutes(combinedRoutes);
                }
            } catch (error) {
                // console.error('Error fetching routes:', error);
            }
        };

        fetchRoutes();
    }, [isAdmin, authParams]);

    return {
        routeElements: generateRoute(routes),
        routes: routes
    };
};

// Export a hook specifically for sidebar
export const useSidebarRoutes = () => {
    const [routes, setRoutes] = useState<RouteType[]>([...appRoutes]);
    const { isAdmin } = useUserFunctions();
    const { authParams } = useAuth();

    useEffect(() => {
        const fetchRoutes = async () => {
            try {
                const result = await getApplicationRoutes(authParams || undefined);
                // console.log('API Response:', result);

                if (result.success && result.routes) {
                    // Process API routes using the same function as useRoutes
                    const apiRoutes = result.routes.map(route => {
                        const processed = processApiRoute(route);
                        // console.log('Processed route:', route.path, processed);
                        return processed;
                    });
                    
                    // console.log('All processed API routes:', apiRoutes);
                    
                    // Combine API routes with app routes
                    const combinedRoutes = [...apiRoutes, ...appRoutes];
                    // console.log('Final combined routes:', combinedRoutes);
                    setRoutes(combinedRoutes);
                }
            } catch (error) {
                // console.error('Error fetching routes:', error);
            }
        };

        fetchRoutes();
    }, [isAdmin, authParams]);

    return routes;
};
