import React from 'react';
import { Paper, ToggleButtonGroup, ToggleButton } from '@mui/material';

export interface Filters {
    status: 'unprocessed' | 'processed';
}

interface CardCategoryFilterProps {
    filters: Filters;
    setFilters: (filters: Filters) => void;
}

const CardCategoryFilter: React.FC<CardCategoryFilterProps> = ({ filters, setFilters }) => {
    const handleStatusChange = (
        _: React.MouseEvent<HTMLElement>,
        newStatus: 'unprocessed' | 'processed'
    ) => {
        if (newStatus !== null) {
            setFilters({ ...filters, status: newStatus });
        }
    };

    return (
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <ToggleButtonGroup
                value={filters.status}
                exclusive
                onChange={handleStatusChange}
                aria-label="card processing status"
                fullWidth
            >
                <ToggleButton value="unprocessed">Unprocessed</ToggleButton>
                <ToggleButton value="processed">Processed</ToggleButton>
            </ToggleButtonGroup>
        </Paper>
    );
};

export default CardCategoryFilter;
