import { useState, useCallback } from 'react';
import * as pokemonSetApi from '../api/pokemonSetApi';
import type {
    PokemonSet,
    PokemonCard,
    UploadCardImageResponse,
    DeleteCardImageResponse,
} from '../api/pokemonSetApi';

export const usePokemonSetFunctions = () => {
    const [loading, setLoading] = useState(false);

    const getPokemonSets = useCallback(async (): Promise<PokemonSet[]> => {
        setLoading(true);
        try {
            const result = await pokemonSetApi.getPokemonSets();
            if (result.success && result.pokemonSets) {
                return result.pokemonSets;
            }
            return [];
        } finally {
            setLoading(false);
        }
    }, []);

    const getPokemonSetCards = useCallback(async (pokemonSetId: number): Promise<PokemonCard[]> => {
        setLoading(true);
        try {
            const result = await pokemonSetApi.getPokemonSetCards(pokemonSetId);
            if (result.success && result.pokemonSetCards) {
                return result.pokemonSetCards;
            }
            return [];
        } finally {
            setLoading(false);
        }
    }, []);

    const uploadCardImage = useCallback(
        async (
            file: File,
            cardId: number,
            image_folder: string,
            filename: string,
            userName: string,
            adminKey: string
        ): Promise<UploadCardImageResponse> => {
            setLoading(true);
            try {
                const result = await pokemonSetApi.uploadCardImage(
                    file,
                    cardId,
                    image_folder,
                    filename,
                    userName,
                    adminKey
                );
                return result;
            } finally {
                setLoading(false);
            }
        },
        []
    );

    const deleteCardImage = useCallback(
        async (
            cardId: number,
            imageType: 'standard' | 'high',
            userName: string,
            adminKey: string
        ): Promise<DeleteCardImageResponse> => {
            setLoading(true);
            try {
                const result = await pokemonSetApi.deleteCardImage(
                    cardId,
                    imageType,
                    userName,
                    adminKey
                );
                return result;
            } finally {
                setLoading(false);
            }
        },
        []
    );

    return {
        getPokemonSets,
        getPokemonSetCards,
        uploadCardImage,
        deleteCardImage,
        loading,
    };
};
