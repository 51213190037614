import React from 'react';
import { Paper, Typography, Box, CircularProgress } from '@mui/material';

interface FeaturedCardHeaderProps {
    totalCards: number;
    featuredCards: number;
    loading?: boolean;
}

const FeaturedCardHeader: React.FC<FeaturedCardHeaderProps> = ({
    totalCards,
    featuredCards,
    loading = false,
}) => {
    return (
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">Featured Card Manager</Typography>
                {loading ? (
                    <CircularProgress size={20} />
                ) : (
                    <Typography variant="subtitle1">
                        Featured Cards: {featuredCards}/{totalCards}
                    </Typography>
                )}
            </Box>
        </Paper>
    );
};

export default FeaturedCardHeader;
