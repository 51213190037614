import { createReducer } from '@reduxjs/toolkit';

const initialSetNumOfReverseCardsState = 0;

const setNumOfReverseCardsStateReducer = createReducer(initialSetNumOfReverseCardsState, {
    SET_NUMOFREVERSECARDS: (state, action) => {
        return action.payload;
    },
});

export default setNumOfReverseCardsStateReducer;
