import { configureStore } from '@reduxjs/toolkit';
import appStateSlice from './features/appStateSlice';
import TCGStateSlice from './features/TCGStateSlice';
import userNameSlice from './features/userNameSlice';
import userSetCollectedCardsSlice from './features/userSetCollectedCardsSlice';
import setNumOfCardsSlice from './features/setNumOfCardsSlice';
import setNumOfReverseCardsSlice from './features/setNumOfReverseCardsSlice';
import seriesNameSlice from './features/seriesNameSlice';
import setNameSlice from './features/setNameSlice';
import printRunSlice from './features/printRunSlice';
import loginPageSlice from './features/loginpageSlice';
import loginPageAuthenticatedSlice from './features/authenticatedSlice';
import cardsDataSlice from './features/cardsDataSlice';
import pageSlice from './features/pageSlice';
import collectedSetsSlice from './features/collectedSetsSlice';
import displayCardsSetSlice from './features/displayCardsSetSlice';
import authReducer from './slices/authSlice';

export const store = configureStore({
    reducer: {
        appState: appStateSlice,
        TCGState: TCGStateSlice,
        userName: userNameSlice,
        userSetCollectedCards: userSetCollectedCardsSlice,
        setNumOfCards: setNumOfCardsSlice,
        setNumOfReverseCards: setNumOfReverseCardsSlice,
        seriesName: seriesNameSlice,
        setName: setNameSlice,
        loginpageState: loginPageSlice,
        printRun: printRunSlice,
        authenticated: loginPageAuthenticatedSlice,
        cardsData: cardsDataSlice,
        page: pageSlice,
        collectedSets: collectedSetsSlice,
        displayCardsSet: displayCardsSetSlice,
        auth: authReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
