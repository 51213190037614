import React, { useState } from 'react';
import {
    Paper,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Box,
    Divider,
    IconButton,
    Collapse,
    Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { defaultTypes } from '../../../data/defaultTypes';
import { defaultRarities } from '../../../data/defaultRarities';

type FilterLayoutProps = {
    checkBoxValues: {
        have: boolean;
        need: boolean;
        rarity: { [key: number]: boolean };
        type: { [key: number]: boolean };
    };
    handleCheckBoxChange: (
        section: 'have' | 'need' | 'rarity' | 'type',
        key: string
    ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    uniqueRarities: number[];
    uniqueTypes: number[];
    totalFilteredCards: number; // New prop for total filtered cards
};

const MobileFilterLayout: React.FC<FilterLayoutProps> = ({
    checkBoxValues,
    handleCheckBoxChange,
    uniqueRarities,
    uniqueTypes,
    totalFilteredCards,
}) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    // Filter defaultRarities to only include unique rarities
    const filteredRarities = defaultRarities
        .filter((rarity) => uniqueRarities.includes(rarity.id))
        .sort((a, b) => a.displayIndex - b.displayIndex);

    // Filter defaultTypes to only include unique types
    const filteredTypes = defaultTypes
        .filter((type) => uniqueTypes.includes(type.id))
        .sort((a, b) => a.displayIndex - b.displayIndex);

    return (
        <Paper elevation={4} style={{ width: '100%', padding: '16px' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Filters</Typography>
                <Typography variant="subtitle1" color="text.secondary">
                    Total Cards: {totalFilteredCards}
                </Typography>
                <IconButton onClick={toggleExpanded} size="small">
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>
            <Collapse in={expanded}>
                <div className="filter-section">
                    <Typography variant="h6">Card Status</Typography>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkBoxValues.have}
                                    onChange={handleCheckBoxChange('have', 'have')}
                                />
                            }
                            label="I Have"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkBoxValues.need}
                                    onChange={handleCheckBoxChange('need', 'need')}
                                />
                            }
                            label="I Need"
                        />
                    </FormGroup>
                </div>

                <Divider style={{ margin: '16px 0' }} />

                <div className="filter-section">
                    <Typography variant="h6">Rarity</Typography>
                    <Grid container spacing={1}>
                        {filteredRarities.map((rarity) => (
                            <Grid item xs={6} key={rarity.id}>
                                <FormControlLabel
                                    control={
                                        <Box display="flex" alignItems="center" paddingRight="4px">
                                            <Checkbox
                                                checked={checkBoxValues.rarity[rarity.id] || false}
                                                onChange={handleCheckBoxChange(
                                                    'rarity',
                                                    rarity.id.toString()
                                                )}
                                            />
                                            <img
                                                src={rarity.iconUrl}
                                                alt={rarity.name}
                                                width="24"
                                                height="24"
                                                style={{ marginLeft: '4px' }}
                                            />
                                        </Box>
                                    }
                                    label={rarity.name}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>

                <Divider style={{ margin: '16px 0' }} />

                <div className="filter-section">
                    <Typography variant="h6">Type</Typography>
                    <Grid container spacing={1}>
                        {filteredTypes.map((type) => (
                            <Grid item xs={6} key={type.id}>
                                <FormControlLabel
                                    control={
                                        <Box display="flex" alignItems="center" paddingRight="4px">
                                            <Checkbox
                                                checked={checkBoxValues.type[type.id] || false}
                                                onChange={handleCheckBoxChange(
                                                    'type',
                                                    type.id.toString()
                                                )}
                                            />
                                            <img
                                                src={type.iconUrl}
                                                alt={type.name}
                                                width="24"
                                                height="24"
                                                style={{ marginLeft: '4px' }}
                                            />
                                        </Box>
                                    }
                                    label={type.name}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Collapse>
        </Paper>
    );
};

export default MobileFilterLayout;
