import AdminDashboard from '../pages/admin/dashboard/AdminDashboard';
import AdminPageLayout from '../pages/admin/AdminPageLayout';
import AdminPageIndex from '../pages/admin/AdminPageIndex';
import CardCategoryManager from '../pages/admin/tools/CardCategoryManager/CardCategoryManager';
import CardImageManager from '../pages/admin/tools/CardImageManager/CardImageManager';
import FeaturedCardManager from '../pages/admin/tools/FeaturedCardManager/FeaturedCardManager';
import CameoCardManager from '../pages/admin/tools/CameoCardManager/CameoCardManager';
import MyPokedex from '../pages/myPokedex/MyPokedex';
import PokemonDetail from '../pages/myPokedex/PokemonDetail';

const componentRegistry: Record<string, React.ComponentType<any>> = {
    'AdminDashboard': AdminDashboard,
    'AdminPageLayout': AdminPageLayout,
    'AdminPageIndex': AdminPageIndex,
    'CardCategoryManager': CardCategoryManager,
    'CardImageManager': CardImageManager,
    'FeaturedCardManager': FeaturedCardManager,
    'CameoCardManager': CameoCardManager,
    'MyPokedex': MyPokedex,
    'PokemonDetail': PokemonDetail
};

export const getComponent = (componentName: string): React.ComponentType<any> | null => {
    const component = componentRegistry[componentName];
    if (!component) {
        // console.warn(`Component "${componentName}" not found in registry`);
    }
    return component || null;
}; 