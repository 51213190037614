import React from 'react';
import { Box } from '@mui/material';
import { PokemonSetSelector } from '../../../../components/PokemonSetSelector';

interface Props {
    selectedSetId: number | null;
    onSetChange: (setId: number | null) => void;
}

const CardSetSelector: React.FC<Props> = ({ selectedSetId, onSetChange }) => {
    return (
        <Box sx={{ mb: 3 }}>
            <PokemonSetSelector value={selectedSetId} onChange={onSetChange} />
        </Box>
    );
};

export default CardSetSelector;
