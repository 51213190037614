import React, { useRef, useEffect, useState } from 'react';
import {
    Box,
    IconButton,
    Typography,
    Card,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    SelectChangeEvent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAdminFunctions } from '../../../../hooks/useAdminFunctions';
import '../../../../css/popup.css';
import assets from '../../../../assets';

type CardType = 'Pokemon' | 'Trainer' | 'Energy' | 'Other';

interface CategoryCard {
    id: number;
    name: string;
    imagelocation: string;
    imageLocation2x: string | null;
    setName: string;
    seriesName: string;
    isProcessed: boolean;
}

interface Props {
    cardInfo: CategoryCard;
    onClose: () => void;
    onSubmitSuccess?: () => void;
}

const CategoryCardTemplate: React.FC<Props> = ({ cardInfo, onClose, onSubmitSuccess }) => {
    const [selectedType, setSelectedType] = useState<CardType | ''>('');
    const { updateCardCategory, loading } = useAdminFunctions();
    const containerRef = useRef<HTMLDivElement>(null);

    const handleTypeChange = (event: SelectChangeEvent) => {
        setSelectedType(event.target.value as CardType);
    };

    const handleSubmit = async () => {
        if (!selectedType) return;

        const success = await updateCardCategory(cardInfo.id, selectedType);
        if (success) {
            onSubmitSuccess?.();
            onClose();
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            const isSelectClick = target.closest('.MuiSelect-root') !== null;
            const isMenuClick = target.closest('.MuiMenu-paper') !== null;

            if (isSelectClick || isMenuClick) {
                return;
            }

            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <Box className="popup-overlay">
            <Box ref={containerRef} className="popup-container">
                <Box className="popup-header">
                    <h2 className="popup-title">{cardInfo.name}</h2>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className="popup-content">
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                width: '100%',
                                border: 'none',
                                boxShadow: 'none',
                            }}
                        >
                            <LazyLoadImage
                                src={cardInfo.imageLocation2x || cardInfo.imagelocation}
                                alt={cardInfo.name}
                                effect="blur"
                                placeholderSrc={assets.site.images.backupImage}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    maxWidth: '400px',
                                    objectFit: 'contain',
                                    background: 'transparent',
                                    boxShadow: 'none',
                                    border: 'none',
                                }}
                            />
                            <Box
                                sx={{
                                    flex: 1,
                                    p: 3,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                }}
                            >
                                <Typography variant="h4" component="h3">
                                    {cardInfo.name}
                                </Typography>

                                <Divider />

                                <Box
                                    sx={{
                                        bgcolor: 'background.paper',
                                        borderRadius: 1,
                                        p: 2,
                                        display: 'flex',
                                        gap: 2,
                                        '& > div': { flex: 1 },
                                    }}
                                >
                                    <div>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            Series Name
                                        </Typography>
                                        <Typography variant="body1">
                                            {cardInfo.seriesName}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant="subtitle2" color="text.secondary">
                                            Set Name
                                        </Typography>
                                        <Typography variant="body1">{cardInfo.setName}</Typography>
                                    </div>
                                </Box>

                                <Box
                                    sx={{
                                        bgcolor: 'background.paper',
                                        borderRadius: 1,
                                        p: 2,
                                    }}
                                >
                                    <Typography variant="subtitle2" color="text.secondary">
                                        Processing Status
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color={
                                            cardInfo.isProcessed ? 'success.main' : 'warning.main'
                                        }
                                    >
                                        {cardInfo.isProcessed ? 'Processed' : 'Unprocessed'}
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        bgcolor: 'background.paper',
                                        borderRadius: 1,
                                        p: 2,
                                        width: '100%',
                                    }}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        color="text.secondary"
                                        gutterBottom
                                    >
                                        What type of card is this?
                                    </Typography>
                                    <FormControl fullWidth sx={{ mb: 2 }}>
                                        <InputLabel id="card-type-label">Card Type</InputLabel>
                                        <Select
                                            labelId="card-type-label"
                                            value={selectedType}
                                            label="Card Type"
                                            onChange={handleTypeChange}
                                        >
                                            <MenuItem value="Pokemon">Pokemon</MenuItem>
                                            <MenuItem value="Trainer">Trainer</MenuItem>
                                            <MenuItem value="Energy">Energy</MenuItem>
                                            <MenuItem value="Other">Other</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={handleSubmit}
                                        disabled={!selectedType || loading}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CategoryCardTemplate;
