import { Outlet } from 'react-router-dom';
import { Box, Toolbar, useMediaQuery } from '@mui/material';
import colorConfigs from '../../configs/colorConfigs';
import sizeConfigs from '../../configs/sizeConfigs';
import Sidebar from '../layout/Sidebar';
import Topbar from '../layout/Topbar';
import Bottombar from './Bottombar';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const MainLayout = () => {
    // Check if the user is authenticated
    let authenticatedStorage = localStorage.getItem('authenticated');
    // Get the current page from Redux store
    const page = useSelector((state: RootState) => state.page);
    // Check if the screen is large (width >= 789px)
    const isLargeScreen = useMediaQuery('(min-width:789px)');

    return (
        <Box sx={{ display: 'flex' }}>
            {/* Render Topbar only on small screens */}
            {!isLargeScreen && <Topbar />}

            {/* Sidebar container */}
            <Box
                component="nav"
                sx={{
                    width: sizeConfigs.sidebar.width,
                    flexShrink: 0,
                    '@media (max-width: 789px)': {
                        display: 'none', // Hide the sidebar on small screens
                    },
                }}
            >
                <Sidebar />
            </Box>

            {/* Main content area */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 2,
                    width: `100%`, // Full width on all screen sizes
                    minHeight: '100vh',
                    backgroundColor: colorConfigs.mainBg,
                }}
            >
                {/* Add top padding on small screens to account for Topbar */}
                {!isLargeScreen && <Toolbar />}

                {/* Render the current route's component */}
                <Outlet />

                {/* Render Bottombar only if authenticated and on the 'Set' page */}
                {authenticatedStorage === 'true' && page === 'Set' ? <Bottombar /> : <></>}
            </Box>
        </Box>
    );
};

export default MainLayout;
