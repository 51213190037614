import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Pokemon } from '../../hooks/usePokeDex';
import assets from '../../assets';

interface PokemonCardProps {
    pokemon: Pokemon;
    hasCaught: boolean;
}

const MobilePokemonCard: React.FC<PokemonCardProps> = ({ pokemon, hasCaught }) => {
    return (
        <Paper
            elevation={3}
            sx={{
                p: 1,
                position: 'relative',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'box-shadow 0.3s, transform 0.3s',
                '&:active': {
                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                    transform: 'translateY(-2px)',
                },
            }}
        >
            <Typography variant="subtitle1" align="center" gutterBottom>
                {pokemon.name}
            </Typography>
            <Box display="flex" justifyContent="center" mb={1}>
                <LazyLoadImage
                    src={pokemon.image || `https://mypokellection.com/Pokedex/${pokemon.id}.png`}
                    alt={pokemon.name}
                    effect="blur"
                    width={64}
                    height={64}
                    style={{
                        objectFit: 'contain',
                    }}
                />
            </Box>
            <Typography variant="body2" align="center" gutterBottom>
                #{pokemon.id.toString().padStart(3, '0')}
            </Typography>
            <Box mt="auto" display="flex" justifyContent="space-between">
                <Typography variant="body2" align="center">
                    Feature Cards: {pokemon.number_featured_cards}
                </Typography>
                <Typography variant="body2" align="center">
                    Cameo Cards: {pokemon.number_cameo_cards}
                </Typography>
            </Box>
            <Box mt={1} display="flex" justifyContent="flex-end">
                <LazyLoadImage
                    src={
                        hasCaught ? assets.site.images.pokeballCaught : assets.site.images.pokeball
                    }
                    alt={hasCaught ? 'Caught' : 'Not caught'}
                    effect="blur"
                    width={24}
                    height={24}
                />
            </Box>
        </Paper>
    );
};

export default MobilePokemonCard;
