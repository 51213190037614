import { useState, useEffect, useCallback } from 'react';
import * as cardApi from '../api/cardApi';
import { useAuth } from '../context/AuthContext';

// Add this new type definition
type FeaturedCard = {
    isReverseHolo: any;
    id: number;
    iconLocation: string;
    numberOfCards: number;
    name: string;
    number: string;
    market_price: number;
    product_id: string;
};

type CameoCard = {
    isReverseHolo: any;
    id: number;
    iconLocation: string;
    numberOfCards: number;
    name: string;
    number: string;
    market_price: number;
    product_id: string;
};

export const useCardFunctions = (
    username: string,
    isAuthenticated: boolean,
    pokemonId: number | null
) => {
    const { authParams } = useAuth();
    const [loading, setLoading] = useState(true);

    const [featuredPokemonCards, setFeaturedPokemonCards] = useState(0);
    const [cameoPokemonCards, setCameoPokemonCards] = useState(0);
    const [caughtPokemon, setCaughtPokemon] = useState(0);
    const [caughtPokemonIds, setCaughtPokemonIds] = useState<number[]>([]);
    const [featuredCaughtPokemonCards, setFeaturedCaughtPokemonCards] = useState(0);
    const [cameoCaughtPokemonCards, setCameoCaughtPokemonCards] = useState(0);
    const [pokedexValue, setPokedexValue] = useState(0);
    const [cardLoading, setCardLoading] = useState(false);

    const [favoritePokemonCardCount, setFavoritePokemonCardCount] = useState(0);
    const [favoritePokemonFeaturedCardCount, setFavoritePokemonFeaturedCardCount] = useState(0);
    const [favoritePokemonCameoCardCount, setFavoritePokemonCameoCardCount] = useState(0);
    const [collectedFavoritePokemonCardCount, setCollectedFavoritePokemonCardsCount] = useState(0);
    const [
        collectedFavoritePokemonFeaturedCardCount,
        setCollectedFavoritePokemonFeaturedCardsCount,
    ] = useState(0);
    const [collectedFavoritePokemonCameoCardCount, setCollectedFavoritePokemonCameoCardsCount] =
        useState(0);

    // Add this new state
    const [featuredCards, setFeaturedCards] = useState<FeaturedCard[]>([]);
    const [cameoCards, setCameoCards] = useState<CameoCard[]>([]);

    const [featuredCaughtPokemonCardIds, setFeaturedCaughtPokemonCardIds] = useState<number[]>([]);
    const [cameoCaughtPokemonCardIds, setCameoCaughtPokemonCardIds] = useState<number[]>([]);

    // Add new functions to update the arrays
    const updateFeaturedCaughtIds = (cardId: number, isAdding: boolean) => {
        setFeaturedCaughtPokemonCardIds((prev) =>
            isAdding ? [...prev, cardId] : prev.filter((id) => id !== cardId)
        );
    };

    const updateCameoCaughtIds = (cardId: number, isAdding: boolean) => {
        setCameoCaughtPokemonCardIds((prev) =>
            isAdding ? [...prev, cardId] : prev.filter((id) => id !== cardId)
        );
    };

    const fetchCardCounts = useCallback(async () => {
        setCardLoading(true);
        try {
            const [featuredResult, cameoResult] = await Promise.all([
                cardApi.getFeaturedPokemonCardCount(),
                cardApi.getCameoPokemonCardCount(),
            ]);

            if (featuredResult.success) {
                setFeaturedPokemonCards(featuredResult.featuredPokemonCards || 0);
            }
            if (cameoResult.success) {
                setCameoPokemonCards(cameoResult.cameoPokemonCards || 0);
            }

            if (isAuthenticated && authParams && username && pokemonId) {
                const [
                    caughtResult,
                    featuredCaughtResult,
                    cameoCaughtResult,
                    pokedexValueResult,
                    featuredCaughtIdsResult,
                    cameoCaughtIdsResult,
                ] = await Promise.all([
                    cardApi.getUserCaughtPokemon(username),
                    cardApi.getFeaturedCaughtPokemonCardCount(username),
                    cardApi.getCameoCaughtPokemonCardCount(username),
                    cardApi.getUserPokedexValue(authParams, username),
                    cardApi.getFeaturedCaughtPokemonCardIds(username, authParams, pokemonId),
                    cardApi.getCameoCaughtPokemonCardIds(username, authParams, pokemonId),
                ]);

                if (caughtResult.success) {
                    setCaughtPokemon(caughtResult.caughtPokemonCount || 0);
                    setCaughtPokemonIds(caughtResult.caughtPokemonIds || []);
                }
                if (featuredCaughtResult.success) {
                    setFeaturedCaughtPokemonCards(
                        featuredCaughtResult.featuredCaughtPokemonCards || 0
                    );
                }
                if (cameoCaughtResult.success) {
                    setCameoCaughtPokemonCards(cameoCaughtResult.cameoCaughtPokemonCards || 0);
                }
                if (pokedexValueResult.success) {
                    setPokedexValue(pokedexValueResult.pokedexValue || 0);
                }
                if (featuredCaughtIdsResult.success) {
                    setFeaturedCaughtPokemonCardIds(
                        featuredCaughtIdsResult.featuredCaughtPokemonCardIds || []
                    );
                }
                if (cameoCaughtIdsResult.success) {
                    setCameoCaughtPokemonCardIds(
                        cameoCaughtIdsResult.cameoCaughtPokemonCardIds || []
                    );
                }
            } else {
                //console.log('Skipping authenticated API calls because user is not authenticated');
            }

            if (isAuthenticated && authParams && username && pokemonId) {
                const [
                    favoriteCardCountResult,
                    favoriteFeaturedCardCountResult,
                    favoriteCameoCardCountResult,
                    collectedFavoriteCardCountResult,
                    collectedFavoriteFeaturedCardCountResult,
                    collectedFavoriteCameoCardCountResult,
                ] = await Promise.all([
                    cardApi.getFavoritePokemonCardCount(authParams, username, pokemonId),
                    cardApi.getFavoritePokemonFeaturedCardCount(authParams, username, pokemonId),
                    cardApi.getFavoritePokemonCameoCardCount(authParams, username, pokemonId),
                    cardApi.getCollectedFavoritePokemonCardCount(authParams, username, pokemonId),
                    cardApi.getCollectedFavoritePokemonFeaturedCardCount(
                        authParams,
                        username,
                        pokemonId
                    ),
                    cardApi.getCollectedFavoritePokemonCameoCardCount(
                        authParams,
                        username,
                        pokemonId
                    ),
                ]);

                if (favoriteCardCountResult.success) {
                    setFavoritePokemonCardCount(
                        favoriteCardCountResult.favoritePokemonCardCount || 0
                    );
                }
                if (favoriteFeaturedCardCountResult.success) {
                    setFavoritePokemonFeaturedCardCount(
                        favoriteFeaturedCardCountResult.favoritePokemonFeaturedCardCount || 0
                    );
                }
                if (favoriteCameoCardCountResult.success) {
                    setFavoritePokemonCameoCardCount(
                        favoriteCameoCardCountResult.favoritePokemonCameoCardCount || 0
                    );
                } else {
                    // console.error(
                    //     'Error fetching favoritePokemonCameoCardCount:',
                    //     favoriteCameoCardCountResult
                    // );
                }
                if (collectedFavoriteCardCountResult.success) {
                    setCollectedFavoritePokemonCardsCount(
                        collectedFavoriteCardCountResult.collectedFavoritePokemonCardCount || 0
                    );
                }
                if (collectedFavoriteFeaturedCardCountResult.success) {
                    setCollectedFavoritePokemonFeaturedCardsCount(
                        collectedFavoriteFeaturedCardCountResult.collectedFavoritePokemonFeaturedCardCount ||
                            0
                    );
                }
                if (collectedFavoriteCameoCardCountResult.success) {
                    setCollectedFavoritePokemonCameoCardsCount(
                        collectedFavoriteCameoCardCountResult.collectedFavoritePokemonCameoCardCount ||
                            0
                    );
                }
            }
        } catch (error) {
            // console.error('Error in fetchCardCounts:', error);
        } finally {
            setCardLoading(false);
        }
    }, [username, authParams, isAuthenticated, pokemonId]);

    // Add this new function
    const fetchFeaturedCards = useCallback(async (pokemonId: number) => {
        try {
            const result = await cardApi.getFeaturedCards(pokemonId);
            if (result.success) {
                setFeaturedCards(result.featuredCards);
            }
        } catch (error) {
            // console.error('Error fetching featured cards:', error);
        }
    }, []);

    const fetchCameoCards = useCallback(async (pokemonId: number) => {
        try {
            const result = await cardApi.getCameoCards(pokemonId);
            if (result.success) {
                setCameoCards(result.cameoCards);
            }
        } catch (error) {
            // console.error('Error fetching cameo cards:', error);
        }
    }, []);

    useEffect(() => {
        fetchCardCounts();
    }, [fetchCardCounts]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (pokemonId !== null) {
                    await Promise.all([fetchFeaturedCards(pokemonId), fetchCameoCards(pokemonId)]);
                }
            } catch (error) {
                // console.error('Error fetching card data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (pokemonId) {
            fetchData();
        }
    }, [pokemonId, fetchFeaturedCards, fetchCameoCards]);

    return {
        featuredPokemonCards,
        cameoPokemonCards,
        caughtPokemon,
        caughtPokemonIds,
        featuredCaughtPokemonCards,
        cameoCaughtPokemonCards,
        pokedexValue,
        cardLoading,
        favoritePokemonCardCount,
        favoritePokemonFeaturedCardCount,
        favoritePokemonCameoCardCount,
        collectedFavoritePokemonCardCount,
        collectedFavoritePokemonFeaturedCardCount,
        collectedFavoritePokemonCameoCardCount,
        refreshCardCounts: fetchCardCounts,
        featuredCards,
        fetchFeaturedCards,
        cameoCards,
        fetchCameoCards,
        featuredCaughtPokemonCardIds,
        cameoCaughtPokemonCardIds,
        updateFeaturedCaughtIds,
        updateCameoCaughtIds,
        loading,
    };
};
