import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './components/layout/MainLayout';
import { useRoutes } from './routes/index';
import ReactGA from 'react-ga4';
import { AuthProvider } from './context/AuthContext';

ReactGA.initialize('G-677WMVXWLS');

const AppRoutes: React.FC = () => {
    const { routeElements } = useRoutes();
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MainLayout />}>
                    {routeElements}
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

const App: React.FC = () => {
    return (
        <AuthProvider>
            <AppRoutes />
        </AuthProvider>
    );
};

export default App;
