import { createReducer } from '@reduxjs/toolkit';

const initialDisplayCardsSetReducer = 'Unlimited';

const displayCardsSetReducer = createReducer(initialDisplayCardsSetReducer, {
    SET_DISPLAYCARDS: (state, action) => {
        return action.payload;
    },
});

export default displayCardsSetReducer;
