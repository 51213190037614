import React from 'react';
import { Paper, Typography, Box, CircularProgress } from '@mui/material';

interface CameoCardHeaderProps {
    totalCards: number;
    cameoCards: number;
    loading?: boolean;
}

const CameoCardHeader: React.FC<CameoCardHeaderProps> = ({
    totalCards,
    cameoCards,
    loading = false,
}) => {
    return (
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5">Cameo Card Manager</Typography>
                {loading ? (
                    <CircularProgress size={20} />
                ) : (
                    <Typography variant="subtitle1">
                        Cameo Cards: {cameoCards}/{totalCards}
                    </Typography>
                )}
            </Box>
        </Paper>
    );
};

export default CameoCardHeader;
